import { getConfig } from 'config/getConfig';
import { TCardContext } from 'libs/card/src/contexts/card/TCardContext';

export const getImgSrcContextFn = (
  imageWidth = 1000
): TCardContext['getImgSrc'] => {
  return ({ image, scale }) =>
    `${getConfig('USER_MEDIA_URL')}${image.uri}?tr=w-${Math.ceil(
      imageWidth * (scale ?? 1)
    )}`;
};
