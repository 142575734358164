import React from 'react';
import { BaseRichText } from '../property/BaseRichText';
import { RichTextElementProps } from './RichTextElementProps';

export const StaticRichTextElement: React.FC<RichTextElementProps> = (
  props
) => (
  <BaseRichText
    text={props.textElement.text}
    className={props.className}
    onClick={props.onClick}
  />
);
