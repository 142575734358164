import React, { useContext } from 'react';
import * as styles from './allFrameStyles.module.scss';
import { BoxPageFrame } from '../BoxPageFrame/BoxPageFrame';
import { BorderTopBottomPageFrame } from '../BorderTopBottomPageFrame/BorderTopBottomPageFrame';
import { useBorderSize } from '../../../../../hooks/useBorderSize';
import { PageSizesContext } from '../../../../../contexts/page/PageSizesContext';
import { mmValueToEm } from '../../../../../utils/measurements/mmValueToEm';
import { PageContext } from '../../../../../contexts/page/PageContext';

type Props = {
  pageRank: number;
};

export const AllPageFrame: React.FC<Props> = (props) => {
  const borderSize = useBorderSize();
  const { cardSettings } = useContext(PageContext);
  const { baseFontSize } = useContext(PageSizesContext);

  if (cardSettings.pageFrame === 'none') {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          marginTop:
            (mmValueToEm(cardSettings.pageMarginTop) * baseFontSize) / 2 -
            borderSize / 2,
          marginRight:
            (mmValueToEm(cardSettings.pageMarginRight) * baseFontSize) /
              (cardSettings.pageFrame === 'box' ? 2 : 1) -
            borderSize / 2,
          marginBottom:
            (mmValueToEm(cardSettings.pageMarginBottom) * baseFontSize) / 2 -
            borderSize / 2,
          marginLeft:
            (mmValueToEm(cardSettings.pageMarginLeft) * baseFontSize) /
              (cardSettings.pageFrame === 'box' ? 2 : 1) -
            borderSize / 2,
        }}
      >
        {cardSettings.pageFrame === 'box' && (
          <BoxPageFrame cardSettings={cardSettings} pageRank={props.pageRank} />
        )}
        {cardSettings.pageFrame === 'top-bottom' && (
          <BorderTopBottomPageFrame
            cardSettings={cardSettings}
            pageRank={props.pageRank}
          />
        )}
      </div>
    </div>
  );
};
