import classnames from 'classnames';
import React from 'react';
import { useBorderSize } from '../../../../../hooks/useBorderSize';
import { SlotBorderProps } from '../SlotBorderProps';

export const SolidBorder: React.FC<SlotBorderProps> = (props) => {
  const size = useBorderSize();

  const style: React.CSSProperties = {
    background: props.color,
  };

  switch (props.position) {
    case 'top':
    case 'bottom':
      style.height = size;
      break;
    case 'left':
    case 'right':
      style.width = size;
      break;
  }

  return (
    <div
      className={classnames(
        `solid-slot-border-${props.position}`,
        props.className
      )}
      style={style}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
    />
  );
};
