import React from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { Slots } from '../../slots/Slots/Slots';
import { SlotsWrapper } from '../../slots/SlotsWrapper/SlotsWrapper';
import { PageContainer } from '../PageContainer/PageContainer';
import { PageContentWrapper } from '../PageContentWrapper/PageContentWrapper';
import { AllPageFrame } from '../pageframes/AllPageFrame/AllPageFrame';
import { PageProps } from './PageProps';

export const StaticPage: React.FC<PageProps> = (props) => {
  return (
    <PageContext.Consumer>
      {({
        Footer,
        PageNumber,
        PageBackground,
        pageTemplate,
        Advertisement,
      }) => (
        <div className={props.className}>
          <PageContainer overflowHidden={props.overflowHidden}>
            <PageBackground pageData={props.pageData} />

            <AllPageFrame pageRank={props.pageData.rank} />

            <PageNumber pageRank={props.pageData.rank} />
            {!props.noAds && <Advertisement projectId={props.projectId} />}

            <PageContentWrapper>
              <SlotsWrapper>
                <Slots
                  slotsData={props.pageData.slots}
                  pageId={props.pageData.id}
                  pageRank={props.pageData.rank}
                  slotDefinitions={pageTemplate.slots}
                  parentSlotNames={[]}
                />

                <Footer />
              </SlotsWrapper>
            </PageContentWrapper>
          </PageContainer>
        </div>
      )}
    </PageContext.Consumer>
  );
};
