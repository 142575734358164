import classnames from 'classnames';
import React, { HTMLProps, useContext } from 'react';
import { PageContext } from '../../../contexts/page/PageContext';
import { isEmptyText } from '../../../utils/text/isEmptyText';
import { RichText } from '../../utils/RichText/RichText';
import * as styles from './staticFooterStyles.module.scss';

export const StaticFooter = React.forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { cardSettings, pageTemplate, PlainSlot } = useContext(PageContext);

  if (!pageTemplate.hasFooter || isEmptyText(cardSettings.footerText)) {
    return null;
  }
  return (
    <div {...props} ref={ref}>
      <PlainSlot noSpaceTop>
        {!isEmptyText(cardSettings.footerText) && (
          <div className={classnames(className, styles.textWrapper)}>
            <RichText variant="small" text={cardSettings.footerText} />
          </div>
        )}
      </PlainSlot>
    </div>
  );
});
