import React from 'react';
import { MenuTitleDecorationType } from '../../components/card/modules/MenuModule/MenuTitleDecoration/MenuTitleDecorationType';
import { DividerType } from '../../components/card/slots/dividers/DividerType';
import { DEFAULT_BORDER_WIDTH, DEFAULT_PAGE_MARGIN } from '../../constants';
import { BLACK, WHITE } from '../../utils/color/colors';
import { DIN_A4_PORTRAIT } from '../../utils/size/dinFormats';
import { TCardContext } from './TCardContext';

const Blank: React.FC = () => null;

export const initialCardContextValues: TCardContext = {
  elements: {},
  cardSettings: {
    colors: {
      primary: BLACK,
      headlines: BLACK,
      text: BLACK,
      background: WHITE,
    },
    currency: 'EUR',
    fontSetId: '',
    companyLogoId: null,
    footerText: '',
    pageNumbersStartAt: 0,
    showPageNumbers: true,
    documentFormat: 'din-a4-portrait',
    ...DIN_A4_PORTRAIT,
    guidesEnabled: false,
    outputFormat: 'card',
    pageFrame: 'none',
    menuVariant: 'left-line-price',
    scale: 1,
    spaceScale: 1,
    equalPageBackgrounds: true,
    pageMarginTop: DEFAULT_PAGE_MARGIN,
    pageMarginRight: DEFAULT_PAGE_MARGIN,
    pageMarginBottom: DEFAULT_PAGE_MARGIN,
    pageMarginLeft: DEFAULT_PAGE_MARGIN,
    pageMarginMode: 'all-equal',
    borderWidth: DEFAULT_BORDER_WIDTH,
    dividerType: DividerType.None,
    menuTitleDecoration: MenuTitleDecorationType.None,
  },
  RichTextElement: Blank,
  MenuCategoryNamePropertyElement: Blank,
  DishNamePropertyElement: Blank,
  DishDescriptionPropertyElement: Blank,
  DishPricePropertyElement: Blank,
  DishElement: Blank,
  DishesWrapper: Blank,
  ModuleWrapper: Blank,
  PlainSlot: Blank,
  EmptySlotContent: Blank,
  SlotContentWrapper: Blank,
  Page: Blank,
  Footer: Blank,
  PageNumber: Blank,
  PageBackground: Blank,
  PageFrameElementsWrapper: Blank,
  ImageElement: Blank,
  SlotBorder: Blank,
  Advertisement: Blank,
  env: 'undefined',
  getImgSrc: ({ image }) => image.uri,
};
