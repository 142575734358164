import React from 'react';
import { SlotContext } from '../../../../contexts/slot/SlotContext';
import { IBaseSlotDefinition } from '../../../../creation/pageTemplates/IBaseSlotDefinition';
import { IContentSlotDefinition } from '../../../../creation/pageTemplates/IContentSlotDefinition';
import { IGroupSlotDefinition } from '../../../../creation/pageTemplates/IGroupSlotDefinition';
import { TSlotDataMap } from '../../../../data/TSlotDataMap';
import { ContentSlot } from '../ContentSlot/ContentSlot';
import { GroupSlot } from '../GroupSlot/GroupSlot';

type Props = {
  pageId: string;
  pageRank: number;
  slotsData: TSlotDataMap;
  slotDefinitions: IBaseSlotDefinition[];
  parentSlotNames: string[];
};

export const Slots: React.FC<Props> = (props) => {
  return (
    <>
      {props.slotDefinitions.map((slotDefinition, index) => {
        const slotName = [...props.parentSlotNames, slotDefinition.name].join(
          '.'
        );

        const slotData = props.slotsData[slotName];
        if (!slotData && slotDefinition.type === 'content') {
          throw new Error(
            `There is no data for slot '${slotName}'. Given slotDefinition [${props.slotDefinitions
              .map((d) => d.name)
              .join(', ')}] does does not match with page ${
              props.pageId
            }: [${Object.keys(props.slotsData).join(', ')}]`
          );
        }
        const level = props.parentSlotNames.length;

        return (
          <SlotContext.Provider
            value={{
              slotName: slotName,
              level,
              modulesInSlot: (slotData && slotData.modules.length) || 0,
              rank: index,
            }}
            key={`${props.pageId}-slot-${slotName}`}
          >
            {slotDefinition.type === 'content' && (
              <ContentSlot
                slotDefinition={slotDefinition as IContentSlotDefinition}
                slotData={slotData}
                type={level === 0 ? 'row' : 'col'}
                spaceLeft={level === 1 && index > 0}
                spaceRight={
                  level === 1 && index < props.slotDefinitions.length - 1
                }
              />
            )}
            {slotDefinition.type === 'group' && (
              <GroupSlot
                pageId={props.pageId}
                pageRank={props.pageRank}
                parentSlotNames={props.parentSlotNames}
                slotsData={props.slotsData}
                slotDefinition={slotDefinition as IGroupSlotDefinition}
              />
            )}
          </SlotContext.Provider>
        );
      })}
    </>
  );
};
