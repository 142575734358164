import React from 'react';
import * as styles from './slotsWrapperStyles.module.scss';
import { mm } from '../../../../utils/measurements/mm';
import { PageContext } from '../../../../contexts/page/PageContext';
import { getSpacing } from '../../../../utils/spacing/getSpacing';
import { SLOT_SPACING } from '../../../../utils/spacing/TSpacingSize';

export const SlotsWrapper: React.FC = (props) => (
  <PageContext.Consumer>
    {({ cardSettings }) => {
      const spacing = mm(
        -getSpacing(SLOT_SPACING, cardSettings.spaceScale) / 2
      );
      return (
        <div
          className={styles.wrapper}
          style={{
            marginTop: spacing,
            marginBottom: spacing,
          }}
        >
          {props.children}
        </div>
      );
    }}
  </PageContext.Consumer>
);
