import { TRGBAColor } from './TRGBAColor';
import { TRGBAColorString } from './TRGBAColorString';

export const parseRGBA = (rgb: TRGBAColorString): TRGBAColor | null => {
  const m = rgb.match(
    /^rgba{0,1}\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*((0\.){0,1}\d+)\s*){0,1}\)$/i
  );
  if (m) {
    const alpha = parseFloat(m[5]);
    return {
      r: parseInt(m[1]),
      g: parseInt(m[2]),
      b: parseInt(m[3]),
      a: isNaN(alpha) ? 1 : alpha,
    };
  }

  return null;
};
