import React, { useContext } from 'react';
import { ModuleContext } from '../../../../../contexts/module/ModuleContext';
import { PageContext } from '../../../../../contexts/page/PageContext';
import { TMenuCategoryElementData } from '../../../../../data/elements/menu/TMenuCategoryElementData';
import { IMenuModuleData } from '../../../../../data/modules/IMenuModuleData';
import { useElementData } from '../../../../../hooks/useElementData';
import { mm } from '../../../../../utils/measurements/mm';
import { getSpacing } from '../../../../../utils/spacing/getSpacing';
import { MENU_CATEGORY_TITLE_SPACING } from '../../../../../utils/spacing/TSpacingSize';
import { propertyOf } from '../../../../../utils/typing/propertyOf';
import { MenuTitleDecoration } from '../MenuTitleDecoration/MenuTitleDecoration';

type Props = {
  moduleData: IMenuModuleData;
};

export const MenuTitle: React.FC<Props> = (props) => {
  const { MenuCategoryNamePropertyElement, cardSettings } = useContext(
    PageContext
  );
  const { innerSpacingLeft, innerSpacingRight } = useContext(ModuleContext);

  const menuCategoryData = useElementData<TMenuCategoryElementData>(
    props.moduleData.references.menuCategoryId
  )!;
  const titleSpacing = getSpacing(
    MENU_CATEGORY_TITLE_SPACING,
    cardSettings.scale * cardSettings.spaceScale
  );

  return (
    <div
      style={{
        marginBottom: mm(titleSpacing),
        marginLeft: innerSpacingLeft,
        marginRight: innerSpacingRight,
      }}
    >
      <MenuTitleDecoration
        decorationType={cardSettings.menuTitleDecoration}
        menuCategoryId={props.moduleData.references.menuCategoryId}
      >
        <MenuCategoryNamePropertyElement
          identifier={{
            type: 'elementproperty',
            moduleId: props.moduleData.id,
            elementId: menuCategoryData.id,
            property: propertyOf<TMenuCategoryElementData>('name'),
          }}
          menuCategoryData={menuCategoryData}
        />
      </MenuTitleDecoration>
    </div>
  );
};
