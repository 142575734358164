import { isValidUUID } from '@shared/utils';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type OwnProps = {};
type Props = OwnProps & RouteComponentProps<{ id: string }>;

const IsValidChannelParamWithoutRouter: React.FC<Props> = (props) => {
  const channelId = props.match.params.id;
  if (isValidUUID(channelId)) {
    return <>{props.children}</>;
  }

  return null;
};

export const IsValidChannelParam = withRouter(
  IsValidChannelParamWithoutRouter
) as React.ComponentType<OwnProps>;
