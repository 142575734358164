import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { theme } from '@shared/styles';
import { Embed } from 'components/Embed/Embed';
import './appStyles.module.scss';
import { IsValidChannelParam } from 'components/IsValidChannelParam/IsValidChannelParam';
import { Demo } from 'components/Demo/Demo';

export const App: React.FC = () => {
  // const newTheme = (theme.palette.primary.main = 'red');
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route path="/demo" component={Demo} />
          <Route
            path="/:id"
            component={() => (
              <IsValidChannelParam>
                <Embed />
              </IsValidChannelParam>
            )}
          />
          <Route render={() => null} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};
