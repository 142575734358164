import { AxiosError, AxiosInstance } from 'axios';
import { HttpStatusCodes } from '../HttpStatusCodes';

type EjectFn = () => void;

export const interceptUnauthorized = (
  axios: AxiosInstance,
  onUnauthorized: (error: AxiosError) => void
): EjectFn => {
  const id = axios.interceptors.response.use(
    (res) => res,
    (err: Error) => {
      const error = err as AxiosError;

      if (
        error.isAxiosError &&
        error.response &&
        error.response.status === HttpStatusCodes.UNAUTHORIZED
      ) {
        onUnauthorized(error);
      }
      return Promise.reject(err);
    }
  );

  return function eject() {
    axios.interceptors.response.eject(id);
  };
};
