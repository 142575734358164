import React from 'react';
import { BaseRichText } from '../BaseRichText';
import { RichTextPropertyElementProps } from './RichTextPropertyElementProps';

export const StaticRichTextPropertyElement: React.FC<RichTextPropertyElementProps> = (
  props
) => (
  <BaseRichText
    text={props.text}
    className={props.className}
    onClick={props.onClick}
  />
);
