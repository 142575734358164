import { TMenuVariant } from './TMenuVariant';

type Alignment = 'left' | 'center';

const alignmentMap: Record<TMenuVariant, Alignment> = {
  'left-line-price': 'left',
  'left-no-line-price': 'left',
  'left-no-price': 'left',
  'center-price-bottom': 'center',
  'center-no-price': 'center',
};

export const getMenuAlignment = (variant: TMenuVariant) =>
  alignmentMap[variant];
