import React from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { TTextElementData } from '../../../../data/elements/TTextElementData';
import { ITextModuleData } from '../../../../data/modules/ITextModuleData';
import { useElementData } from '../../../../hooks/useElementData';
import { getModuleWrapperProps } from '../../../../utils/modules/getModuleWrapperProps';
import { ModuleProps } from '../ModuleProps';

export const TextModule: React.FC<ModuleProps<ITextModuleData>> = (props) => {
  const textElementData = useElementData<TTextElementData>(
    props.moduleData.references.textElementId
  )!;

  return (
    <PageContext.Consumer>
      {({ RichTextElement, ModuleWrapper }) => (
        <ModuleWrapper {...getModuleWrapperProps(props)}>
          <RichTextElement
            textElement={textElementData}
            moduleId={props.moduleData.id}
          />
        </ModuleWrapper>
      )}
    </PageContext.Consumer>
  );
};
