import React from 'react';
import { ModuleProps } from '../ModuleProps';
import { getModule } from '../modulesRegistry';

export const AllModule: React.FC<ModuleProps> = (props) => {
  const Module = getModule(props.moduleData.type);
  if (!Module) {
    return null;
  }
  return <Module {...props} />;
};
