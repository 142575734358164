import classnames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { IContentSlotDefinition } from '../../../../creation/pageTemplates/IContentSlotDefinition';
import { ISlotData } from '../../../../data/ISlotData';
import { mm } from '../../../../utils/measurements/mm';
import { getSpacing } from '../../../../utils/spacing/getSpacing';
import { SLOT_COLUMN_SPACING } from '../../../../utils/spacing/TSpacingSize';
import { AllModule } from '../../modules/AllModule/AllModule';
import { AllDivider } from '../dividers/AllDivider/AllDivider';
import { DividerType } from '../dividers/DividerType';
import { SlotWrapper } from '../SlotWrapper/SlotWrapper';
import * as styles from './contentSlotStyles.module.scss';

type Props = {
  slotDefinition: IContentSlotDefinition;
  slotData: ISlotData;
  spaceLeft?: boolean;
  spaceRight?: boolean;
  type: 'row' | 'col';
};

export const ContentSlot: React.FC<Props> = (props) => {
  const {
    EmptySlotContent,
    SlotContentWrapper,
    SlotBorder,
    cardSettings,
  } = useContext(PageContext);
  const borders = useMemo(
    () => ({
      top: props.slotDefinition.style === 'border-top',
      right: props.slotDefinition.style === 'border-right',
      bottom: props.slotDefinition.style === 'border-bottom',
      left: props.slotDefinition.style === 'border-left',
    }),
    [props.slotDefinition]
  );
  const isEmpty = props.slotData.modules.length === 0;
  const slotSpace = mm(
    getSpacing(SLOT_COLUMN_SPACING, cardSettings.spaceScale)
  );

  return (
    <>
      {borders.left && (
        <SlotBorder color={cardSettings.colors.primary} position="left" />
      )}
      {borders.top && (
        <SlotBorder color={cardSettings.colors.primary} position="top" />
      )}
      <SlotWrapper
        verticalSpace={!isEmpty && props.type === 'row'}
        style={{
          marginRight: props.spaceRight ? slotSpace : undefined,
          marginLeft: props.spaceLeft ? slotSpace : undefined,
        }}
        className={classnames({
          [styles.grow]: props.slotDefinition.grow,
        })}
      >
        <SlotContentWrapper
          slotDefinition={props.slotDefinition}
          isEmpty={isEmpty}
        >
          {isEmpty && <EmptySlotContent />}
          {props.slotData.modules.map((moduleData, index) => (
            <React.Fragment key={moduleData.id}>
              <AllModule moduleData={moduleData} />
              {cardSettings.dividerType !== DividerType.None &&
                moduleData.type === 'menu' &&
                props.slotData.modules[index + 1] &&
                props.slotData.modules[index + 1].type === 'menu' && (
                  <AllDivider />
                )}
            </React.Fragment>
          ))}
        </SlotContentWrapper>
      </SlotWrapper>
      {borders.right && (
        <SlotBorder color={cardSettings.colors.primary} position="right" />
      )}
      {borders.bottom && (
        <SlotBorder color={cardSettings.colors.primary} position="bottom" />
      )}
    </>
  );
};
