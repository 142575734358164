import classnames from 'classnames';
import React, { CSSProperties, useContext, useMemo } from 'react';
import { ModuleContext } from '../../../../contexts/module/ModuleContext';
import { PageContext } from '../../../../contexts/page/PageContext';
import { PageSizesContext } from '../../../../contexts/page/PageSizesContext';
import { ModuleBorderRadius } from '../../../../data/ModuleBorderRadius';
import { ModuleBorderStyle } from '../../../../data/ModuleBorderStyle';
import { isVisibleColor } from '../../../../utils/color/isVisibleColor';
import { mm } from '../../../../utils/measurements/mm';
import { mmValueToEm } from '../../../../utils/measurements/mmValueToEm';
import { getSpacing } from '../../../../utils/spacing/getSpacing';
import { MODULE_SPACING } from '../../../../utils/spacing/TSpacingSize';
import { ModuleWrapperProps } from './ModuleWrapperProps';
import * as styles from './staticModuleWrapperStyles.module.scss';

const borderRadiusMap: Record<ModuleBorderRadius, number> = {
  none: 0,
  small: 2,
  medium: 4,
  large: 6,
};

export const StaticModuleWrapper = React.forwardRef<
  HTMLDivElement,
  ModuleWrapperProps
>((props, ref) => {
  const {
    className,
    moduleData,
    style,
    children,
    backgroundClassName,
    onMouseEnter,
    onMouseLeave,
    onClick,
    applyHorizontalInnerSpacing = true,
    ...divProps
  } = props;
  const { cardSettings } = useContext(PageContext);
  const { baseFontSize } = useContext(PageSizesContext);
  const borderSize = useMemo(
    () => Math.max(1, Math.round(mmValueToEm(0.4) * baseFontSize)),
    [baseFontSize]
  );
  const {
    backgroundColor,
    borderColor,
    borderRadius,
    borderStyle,
  } = moduleData.settings;

  const spacingBase = getSpacing(MODULE_SPACING, cardSettings.spaceScale) / 2;
  const spacing = mm(1 * spacingBase);

  const moduleStyle: CSSProperties = useMemo(
    () => ({
      ...style,
      paddingTop: spacing,
      paddingBottom: spacing,
    }),
    [style, spacing]
  );

  const background: {
    style: CSSProperties;
    spacing: string | undefined;
  } = useMemo(() => {
    const hasBackgroundColor = isVisibleColor(backgroundColor);
    const hasBorder = borderStyle !== ModuleBorderStyle.None;

    const backgroundSpacing =
      hasBackgroundColor || hasBorder ? mm(spacingBase / 2) : undefined;

    const borderRadiusValue = borderRadiusMap[borderRadius];

    return {
      style: {
        backgroundColor: hasBackgroundColor
          ? backgroundColor ?? undefined
          : undefined,
        paddingTop: backgroundSpacing,
        paddingBottom: backgroundSpacing,
        paddingLeft: applyHorizontalInnerSpacing
          ? backgroundSpacing
          : undefined,
        paddingRight: applyHorizontalInnerSpacing
          ? backgroundSpacing
          : undefined,
        border:
          borderStyle !== ModuleBorderStyle.None
            ? `${borderSize}px ${borderStyle ?? undefined} ${borderColor}`
            : undefined,
        borderRadius: borderRadiusValue ? `${borderRadiusValue}em` : undefined,
      },
      spacing: backgroundSpacing,
    };
  }, [
    backgroundColor,
    borderColor,
    borderRadius,
    borderStyle,
    borderSize,
    spacingBase,
    applyHorizontalInnerSpacing,
  ]);

  return (
    <div
      {...divProps}
      ref={ref}
      className={classnames(className, styles.module)}
      style={moduleStyle}
    >
      <div
        className={backgroundClassName}
        style={background.style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        <ModuleContext.Provider
          value={{
            innerSpacingLeft: background.spacing,
            innerSpacingRight: background.spacing,
          }}
        >
          {props.children}
        </ModuleContext.Provider>
      </div>
    </div>
  );
}) as React.ComponentType<ModuleWrapperProps>;
