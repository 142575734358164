import React from 'react';
import { ImageModule } from './ImageModule/ImageModule';
import { MenuModule } from './MenuModule/MenuModule';
import { ModuleProps } from './ModuleProps';
import { TextModule } from './TextModule/TextModule';
import { TModuleType } from './TModuleType';

const modules: Record<TModuleType, React.ComponentType<any>> = {
  menu: MenuModule,
  text: TextModule,
  image: ImageModule,
};

export const getModule = (type: TModuleType) => {
  const module = modules[type];

  if (!module) {
    return null;
  }
  return module as React.ComponentType<ModuleProps>;
};
