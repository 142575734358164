import classnames from 'classnames';
import React, { useContext } from 'react';
import { PageContext } from '../../../../../contexts/page/PageContext';
import { getCurrencySymbol } from '../../../../../utils/currency/getCurrencySymbol';
import { formatPrice } from '../../../../../utils/price/formatPrice';
import { StaticTextPropertyElement } from '../StaticTextPropertyElement/StaticTextPropertyElement';
import { TextPropertyElementProps } from '../StaticTextPropertyElement/TextPropertyElementProps';
import * as styles from './staticPricePropertyElementStyles.module.scss';

export const StaticPricePropertyElement = React.forwardRef<
  HTMLDivElement,
  TextPropertyElementProps
>((props, ref) => {
  const price = parseFloat(props.children!.toString());
  const newChildren =
    typeof price === 'number' && !isNaN(price)
      ? formatPrice(price)
      : props.children;
  const { cardSettings } = useContext(PageContext);
  const currencySymbol = getCurrencySymbol(cardSettings.currency);
  return (
    <StaticTextPropertyElement
      {...props}
      className={classnames(props.className, styles.wrapper)}
      ref={ref}
    >
      <span>{newChildren}</span>
      <span dangerouslySetInnerHTML={{ __html: '&thinsp;' }} />
      <span>{currencySymbol}</span>
    </StaticTextPropertyElement>
  );
});
