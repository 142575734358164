import classnames from 'classnames';
import React, { HTMLProps, useContext } from 'react';
import { ModuleContext } from '../../../../../../contexts/module/ModuleContext';
import { PageContext } from '../../../../../../contexts/page/PageContext';
import { IDishElementData } from '../../../../../../data/elements/menu/IDishElementData';
import { mm } from '../../../../../../utils/measurements/mm';
import { getMenuVariantDefinition } from '../../../../../../utils/menu/getMenuVariantDefinition';
import { getSpacing } from '../../../../../../utils/spacing/getSpacing';
import {
  DISH_DESCRIPTION_TOP_SPACING,
  DISH_PRICE_TOP_SPACING,
  DISH_SPACING,
} from '../../../../../../utils/spacing/TSpacingSize';
import { propertyOf } from '../../../../../../utils/typing/propertyOf';
import { StaticDishPricePropertyElement } from '../StaticDishPricePropertyElement/StaticDishPricePropertyElement';
import { DishElementProps } from './DishElementProps';
import * as styles from './staticDishElementStyles.module.scss';

type Props = HTMLProps<HTMLDivElement>['children'] &
  DishElementProps & {
    innerRef?: React.Ref<HTMLDivElement>;
  };

export const StaticDishElementWithInnerRef: React.FC<Props> = (props) => {
  const {
    DishNamePropertyElement,
    DishDescriptionPropertyElement,
    DishPricePropertyElement,
    cardSettings,
    env,
  } = useContext(PageContext);
  const variantDefinition = getMenuVariantDefinition(cardSettings.menuVariant);
  const { innerSpacingLeft, innerSpacingRight } = useContext(ModuleContext);

  const dishSpacing = mm(
    getSpacing(DISH_SPACING, cardSettings.scale * cardSettings.spaceScale) / 2
  );

  return (
    <div
      className={classnames(styles.entryWrapper, props.className, {
        [styles.alignLeft]: variantDefinition.alignment === 'left',
        [styles.alignCenter]: variantDefinition.alignment === 'center',
      })}
      style={{
        marginTop: dishSpacing,
        marginBottom: dishSpacing,
      }}
      onClick={props.onClick}
      ref={props.innerRef}
    >
      <div
        className={classnames(
          styles.contentWrapper,
          props.contentWrapperClassName
        )}
        style={{
          marginLeft: innerSpacingLeft,
          marginRight: innerSpacingRight,
        }}
      >
        <div className={styles.textWrapper}>
          <div className={styles.nameAndPriceWrapper}>
            <div className={styles.nameWrapper}>
              <DishNamePropertyElement
                identifier={{
                  type: 'groupitemproperty',
                  moduleId: props.moduleData.id,
                  elementId: props.menuCategoryData.id,
                  groupItemId: props.dishData.id,
                  property: propertyOf<IDishElementData>('name'),
                }}
                menuId={props.menuCategoryData.id}
                dishData={props.dishData}
                className={styles.name}
                showDottedLine={variantDefinition.lineToPrice}
                dottedLineWidth={env === 'thumbnail' ? 'tiny' : 'normal'}
              />
            </div>
            {variantDefinition.pricePosition === 'with-title' && (
              <DishPricePropertyElement
                identifier={{
                  type: 'groupitemproperty',
                  moduleId: props.moduleData.id,
                  elementId: props.menuCategoryData.id,
                  groupItemId: props.dishData.id,
                  property: propertyOf<IDishElementData>('price'),
                }}
                dishData={props.dishData}
                menuId={props.menuCategoryData.id}
              />
            )}
          </div>
          {props.dishData.settings.visibility.description && (
            <div
              className={styles.descriptionWrapper}
              style={{
                marginTop: mm(
                  getSpacing(
                    DISH_DESCRIPTION_TOP_SPACING,
                    cardSettings.scale * cardSettings.spaceScale
                  )
                ),
                textAlign: variantDefinition.alignment,
              }}
            >
              <DishDescriptionPropertyElement
                identifier={{
                  type: 'groupitemproperty',
                  moduleId: props.moduleData.id,
                  elementId: props.menuCategoryData.id,
                  groupItemId: props.dishData.id,
                  property: propertyOf<IDishElementData>('description'),
                }}
                className={styles.description}
                dishData={props.dishData}
                menuId={props.menuCategoryData.id}
              />
              {variantDefinition.pricePosition === 'with-title' && (
                <StaticDishPricePropertyElement
                  identifier={{
                    type: 'groupitemproperty',
                    moduleId: props.moduleData.id,
                    elementId: props.menuCategoryData.id,
                    groupItemId: props.dishData.id,
                    property: propertyOf<IDishElementData>('price'),
                  }}
                  className={styles.ghostPrice}
                  dishData={props.dishData}
                  menuId={props.menuCategoryData.id}
                />
              )}
            </div>
          )}
        </div>
        {variantDefinition.pricePosition === 'bottom' && (
          <div
            style={{
              marginTop:
                variantDefinition.pricePosition === 'bottom'
                  ? mm(
                      getSpacing(
                        DISH_PRICE_TOP_SPACING,
                        cardSettings.scale * cardSettings.spaceScale
                      )
                    )
                  : undefined,
            }}
          >
            <DishPricePropertyElement
              identifier={{
                type: 'groupitemproperty',
                moduleId: props.moduleData.id,
                elementId: props.menuCategoryData.id,
                groupItemId: props.dishData.id,
                property: propertyOf<IDishElementData>('price'),
              }}
              dishData={props.dishData}
              menuId={props.menuCategoryData.id}
            />
          </div>
        )}
      </div>
      {props.children}
    </div>
  );
};

export const StaticDishElement = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => <StaticDishElementWithInnerRef innerRef={ref} {...props} />
);
