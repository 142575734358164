import { TFont } from './TFont';
import { TFontSet } from './TFontSet';

const NORMAL_FONT_WEIGHT = 400;
const STRONG_FONT_WEIGHT = 600;

type TMinFontDef = Omit<
  TFont,
  'normalWeight' | 'boldWeight' | 'semiBoldWeight' | 'sizeFactor'
> &
  Partial<
    Pick<TFont, 'normalWeight' | 'boldWeight' | 'semiBoldWeight' | 'sizeFactor'>
  >;

type TMinFontSet = Omit<TFontSet, 'headline' | 'text'> & {
  headline: TMinFontDef;
  text: TMinFontDef;
};

const ROBOTO: TMinFontDef = {
  name: 'Roboto',
  familyStack: 'Roboto, sans-serif',
  lineHeight: 1.5,
  normalWeight: 400,
  boldWeight: 700,
};

const OPEN_SANS: TMinFontDef = {
  name: 'Open Sans',
  familyStack: "'Open Sans', sans-serif",
  lineHeight: 1.5,
  normalWeight: 400,
  boldWeight: 700,
};

const LATO: TMinFontDef = {
  name: 'Lato',
  familyStack: 'Lato, sans-serif',
  lineHeight: 1.45,
  normalWeight: 400,
  boldWeight: 900,
};

const toFontSet = (minFontSet: TMinFontSet): TFontSet => ({
  ...minFontSet,
  headline: {
    ...minFontSet.headline,
    sizeFactor: minFontSet.headline.sizeFactor || 1,
    normalWeight: minFontSet.headline.normalWeight || NORMAL_FONT_WEIGHT,
    boldWeight: minFontSet.headline.boldWeight || STRONG_FONT_WEIGHT,
  },
  text: {
    ...minFontSet.text,
    sizeFactor: minFontSet.text.sizeFactor || 1,
    normalWeight: minFontSet.text.normalWeight || NORMAL_FONT_WEIGHT,
    boldWeight: minFontSet.text.boldWeight || STRONG_FONT_WEIGHT,
  },
});

const minFontSets: TMinFontSet[] = [
  // 0
  {
    id: '7790eb73-ebfc-470c-a743-8bc14a48c2a5',
    headline: {
      lineHeight: 1.2,
      name: 'Halant',
      familyStack: 'Halant, serif',
      normalWeight: 400,
      semiBoldWeight: 500,
      boldWeight: 700,
    },
    text: {
      lineHeight: 1.5,
      name: 'Nunito Sans',
      familyStack: "'Nunito Sans', sans-serif",
      normalWeight: 400,
      boldWeight: 800,
    },
  },

  // 1
  {
    id: '15688fee-027f-4406-8a2e-8428f68b8af7',
    headline: {
      lineHeight: 1.2,
      name: 'Archivo Narrow',
      familyStack: "'Archivo Narrow', sans-serif",
      normalWeight: 400,
      semiBoldWeight: 500,
      boldWeight: 700,
      sizeFactor: 1.05,
    },
    text: {
      lineHeight: 1.5,
      name: 'Merriweather',
      familyStack: 'Merriweather, serif',
      normalWeight: 400,
      boldWeight: 900,
      sizeFactor: 0.95,
    },
  },

  // 2
  {
    id: '3fee1660-0ea9-4242-b15d-2aa68eaae055',
    headline: {
      lineHeight: 1.2,
      name: 'Oswald',
      familyStack: 'Oswald, sans-serif',
      normalWeight: 400,
      boldWeight: 600,
    },
    text: {
      lineHeight: 1.4,
      name: 'Merriweather',
      familyStack: "Merriweather, serif'",
      sizeFactor: 1,
    },
  },

  // 3
  {
    id: '0cfbd7f9-6e29-41ca-a02a-6ce43d79bdf3',
    headline: {
      lineHeight: 1.1,
      name: 'Didact Gothic',
      familyStack: "'Didact Gothic', sans-serif",
      boldWeight: 'bold',
      sizeFactor: 1.05,
    },
    text: {
      lineHeight: 1.5,
      name: 'Arimo',
      familyStack: 'Arimo, sans-serif',
      sizeFactor: 1,
      normalWeight: 400,
      boldWeight: 700,
    },
  },

  // 4
  {
    id: '482436f4-358b-4cf2-b274-e25a4d2ef7cf',
    headline: {
      lineHeight: 1.3,
      name: 'Patua One',
      familyStack: "'Patua One', cursive",
      boldWeight: 'bold',
    },
    text: {
      lineHeight: 1.5,
      name: 'Lora',
      familyStack: 'Lora, serif',
      sizeFactor: 1.1,
      normalWeight: 400,
      boldWeight: 700,
    },
  },

  // 5 deprecated
  {
    id: '529dcf66-2a38-4de6-a41b-89a74979d3bc',
    headline: {
      lineHeight: 1.3,
      name: 'Fjalla One',
      familyStack: "'Fjalla One', sans-serif",
      boldWeight: 'bold',
    },
    text: {
      lineHeight: 1.4,
      name: 'Cantarell',
      familyStack: 'Cantarell, sans-serif',
      sizeFactor: 1.1,
      normalWeight: 400,
      boldWeight: 700,
    },
  },

  // 6
  {
    id: '7768f2b9-b7a9-42e5-b16d-4640468bbaf9',
    headline: {
      lineHeight: 1.3,
      name: 'Abril Fatface',
      familyStack: "'Abril Fatface', cursive",
      sizeFactor: 0.95,
      boldWeight: 'bold',
    },
    text: ROBOTO,
  },

  // 7
  {
    id: '0f5a2b1d-ed6d-42ae-8294-29d9793650e3',
    headline: {
      lineHeight: 1.2,
      name: 'Eczar',
      familyStack: 'Eczar, serif',
      normalWeight: 400,
      semiBoldWeight: 500,
      boldWeight: 600,
    },
    text: {
      lineHeight: 1.5,
      name: 'Gentium+Basic',
      familyStack: "'Gentium Basic', serif",
      normalWeight: 400,
      boldWeight: 700,
      sizeFactor: 1.15,
    },
  },

  // 8
  {
    id: 'a176993f-7204-46c9-9a85-66bcbc1e833b',
    headline: {
      lineHeight: 1.2,
      name: 'Istok Web',
      familyStack: "'Istok Web', sans-serif",
      normalWeight: 400,
      boldWeight: 700,
    },
    text: {
      lineHeight: 1.5,
      name: 'Lora',
      familyStack: 'Lora, serif',
      normalWeight: 400,
      boldWeight: 700,
    },
  },

  // 9 deprecated
  {
    id: '36ce5b8d-5ab1-43e8-9d45-1d7bd6fd32c0',
    headline: {
      lineHeight: 1.5,
      name: 'Pacifico',
      familyStack: 'Pacifico, cursive',
      sizeFactor: 0.9,
      boldWeight: 'bold',
    },
    text: {
      lineHeight: 1.5,
      name: 'Arimo',
      familyStack: 'Arimo, sans-serif',
      normalWeight: 400,
      boldWeight: 700,
    },
  },

  // 10
  {
    id: 'bbf8d090-8fba-46a2-b37b-70cccd8c9357',
    headline: {
      lineHeight: 1.3,
      name: 'Berkshire Swash',
      familyStack: "'Berkshire Swash', cursive",
      boldWeight: 'bold',
    },
    text: LATO,
  },

  // 11
  {
    id: 'aa71db93-c3c3-467c-ab4a-19f2ec47d732',
    headline: {
      lineHeight: 1.2,
      name: 'Amatic SC',
      familyStack: "'Amatic SC', cursive",
      normalWeight: 700,
      boldWeight: 'bold',
      sizeFactor: 1.3,
    },
    text: OPEN_SANS,
  },

  // 12
  {
    id: '2f820890-6abf-4e13-b1c7-8aa727b5a702',
    headline: {
      lineHeight: 1.3,
      name: 'Roboto Slab',
      familyStack: "'Roboto Slab', serif",
      sizeFactor: 0.93,
      normalWeight: 400,
      semiBoldWeight: 500,
      boldWeight: 600,
    },
    text: ROBOTO,
  },

  // 13
  {
    id: 'fec22d3e-e090-4321-a15e-895a921e0e05',
    headline: {
      lineHeight: 1,
      name: 'Qwigley',
      familyStack: 'Qwigley, cursive',
      sizeFactor: 1.7,
      boldWeight: 'bold',
    },
    text: OPEN_SANS,
  },

  // 14
  {
    id: 'ceb95f98-47c0-4d8a-8ca3-fac34c711096',
    headline: {
      lineHeight: 1.2,
      name: 'Dancing Script',
      familyStack: "'Dancing Script', cursive",
      sizeFactor: 1.25,
      normalWeight: 400,
      semiBoldWeight: 500,
      boldWeight: 700,
    },
    text: LATO,
  },

  // 15
  {
    id: '590071d7-7708-49b3-86fc-d36df212be4a',
    headline: {
      lineHeight: 1.2,
      name: 'Rochester',
      familyStack: 'Rochester, cursive',
      sizeFactor: 1.2,
      normalWeight: 400,
      boldWeight: 'bold',
    },
    text: OPEN_SANS,
  },

  // 16
  {
    id: '237fbf55-5e35-4916-97a6-8449fa402f90',
    headline: {
      lineHeight: 1.3,
      name: 'Handlee',
      familyStack: 'Handlee, cursive',
      sizeFactor: 1.1,
      normalWeight: 400,
      boldWeight: 'bold',
    },
    text: LATO,
  },

  // 17
  {
    id: 'd59414a5-480e-497f-a725-97efbd10ad18',
    headline: {
      lineHeight: 1.25,
      name: 'Courier Prime',
      familyStack: "'Courier Prime', monospace",
      normalWeight: 400,
      boldWeight: 700,
      sizeFactor: 1.05,
    },
    text: ROBOTO,
  },

  // 18
  {
    id: '5c452125-449b-4240-ad95-3279000ab508',
    headline: {
      lineHeight: 1.2,
      name: 'Source Sans Pro',
      familyStack: "'Source Sans Pro', sans-serif",
      normalWeight: 600,
      boldWeight: 700,
    },
    text: {
      lineHeight: 1.4,
      name: 'Source Sans Pro',
      familyStack: "'Source Sans Pro', sans-serif",
      normalWeight: 400,
      boldWeight: 700,
      sizeFactor: 1.1,
    },
  },
];

export const fontSets = minFontSets.map(toFontSet);
