import React, { useContext } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { mm } from '../../../../utils/measurements/mm';
import { AdvertisementProps } from './AdvertisementProps';
import * as styles from './staticAdvertisementStyles.module.scss';
const logo = require('./foodcards_logo_de_rotated.svg');

export const StaticAdvertisement: React.FC<AdvertisementProps> = ({
  onClick,
  projectId,
  tooltip,
}) => {
  const { cardSettings } = useContext(PageContext);
  const width = 3.5;
  const bottom = 4;
  const right = 4;
  const padding = 1;

  let horizontalSpace = 0;
  if (cardSettings.pageFrame === 'box') {
    horizontalSpace =
      cardSettings.pageMarginRight / 2 - cardSettings.borderWidth / 2;
  } else {
    horizontalSpace = cardSettings.pageMarginRight;
  }

  const backgroundColor =
    horizontalSpace < width + right
      ? cardSettings.colors.background
      : undefined;
  return (
    <a
      className={styles.wrapper}
      href={`https://www.foodcards.de?utm_source=${projectId}-card`}
      target="_blank"
      style={{
        bottom: mm(bottom - padding / 2),
        right: mm(right - padding / 2),
        width: mm(width),
        padding: mm(padding),
        backgroundColor,
      }}
      title={tooltip || 'erstellt mit www.foodcards.de'}
      onClick={onClick}
      rel="noopener noreferrer"
    >
      <img width="100%" src={logo} alt="erstellt mit www.foodcards.de" />
    </a>
  );
};
