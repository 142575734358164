import React from 'react';
import { IDishElementData } from '../../../../../../data/elements/menu/IDishElementData';
import { propertyOf } from '../../../../../../utils/typing/propertyOf';
import { StaticRichTextPropertyElement } from '../../../property/StaticRichTextPropertyElement/StaticRichTextPropertyElement';
import { DishDescriptionPropertyElementProps } from './DishDescriptionPropertyElementProps';

export const StaticDishDescriptionPropertyElement: React.FC<DishDescriptionPropertyElementProps> = (
  props
) => (
  <StaticRichTextPropertyElement
    identifier={{
      type: 'groupitemproperty',
      moduleId: props.menuId,
      elementId: props.menuId,
      groupItemId: props.dishData.id,
      property: propertyOf<IDishElementData>('description'),
    }}
    className={props.className}
    text={props.dishData.description}
  />
);
