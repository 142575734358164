import { SubscriberList } from '@shared/utils';

const IFRAME_SRC_PROD = 'https://embed.foodcards.de/';
const IFRAME_SRC_DEV = 'https://localhost:3000/';
const INITIAL_HEIGHT = '52px';

export class EmbedCard {
  styleTag: HTMLStyleElement | undefined;
  iframe: HTMLIFrameElement;
  rootElement: HTMLElement;
  channelId: string;

  private subscriberList = new SubscriberList<{
    resize: (height: number) => void;
  }>();

  constructor(
    element: HTMLElement,
    channelId: string,
    options: { color: string | null }
  ) {
    this.rootElement = element;
    this.channelId = channelId;
    this.setRootElementSyles();
    this.iframe = this.createIFrame(options.color);
    this.rootElement.appendChild(this.iframe);
  }

  getIFrameSrouce = (color: string | null) => {
    const URL =
      window.location.host.indexOf('localhost') === -1
        ? IFRAME_SRC_PROD
        : IFRAME_SRC_DEV;

    return `${URL}${this.channelId}${color ? `?color=${color}` : ''}`;
  };

  setRootElementSyles = () => {
    if (!this.rootElement.style.width) {
      this.rootElement.style.width = '100%';
    }
  };

  createIFrame = (color: string | null) => {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', this.getIFrameSrouce(color));
    iframe.setAttribute('scrolling', 'no');

    iframe.style.width = '100%';
    iframe.style.border = 'none';
    iframe.style.background = 'transparent';
    iframe.style.overflow = 'hidden';
    iframe.style.margin = '0 auto';
    iframe.style.padding = '0';
    iframe.style.display = 'block';
    iframe.style.minHeight = INITIAL_HEIGHT;
    iframe.style.height = INITIAL_HEIGHT;
    return iframe;
  };

  createStyleTag = () => {
    const styleTag = document.createElement('style');
    styleTag.setAttribute('type', 'text/css');
    styleTag.setAttribute('media', 'all');
    styleTag.setAttribute('data-foodcards-style', '');
    return styleTag;
  };

  onResize = (cb: (height: number) => void) =>
    this.subscriberList.on('resize', cb);

  setHeight = (height: number) => {
    this.iframe.style.height = height + 'px';
    this.subscriberList.notify('resize', height);
  };

  setCss = (css: string) => {
    if (!this.styleTag) {
      this.styleTag = this.createStyleTag();
      document.head.appendChild(this.styleTag);
    }
    this.styleTag.innerHTML = css;
  };
}
