import classnames from 'classnames';
import React, { useContext } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { mm } from '../../../../utils/measurements/mm';
import { getPageNumber } from '../../../../utils/pagenumber/getPageNumber';
import { showPageNumber } from '../../../../utils/pagenumber/showPageNumber';
import { StaticTextPropertyElement } from '../../elements/property/StaticTextPropertyElement/StaticTextPropertyElement';
import { PageNumberProps } from './PageNumberProps';
import * as styles from './pageNumberStyles.module.scss';

export const StaticPageNumber: React.FC<PageNumberProps> = ({
  pageRank,
  className,
  style,
  ...props
}) => {
  const { cardSettings } = useContext(PageContext);

  if (
    !showPageNumber(
      cardSettings.showPageNumbers,
      cardSettings.pageNumbersStartAt,
      pageRank
    )
  ) {
    return null;
  }

  return (
    <div
      {...props}
      className={styles.wrapper}
      style={{
        ...style,
        left: mm(cardSettings.pageMarginLeft),
        right: mm(cardSettings.pageMarginRight),
        height: mm(cardSettings.pageMarginBottom),
      }}
    >
      <span className={classnames(className, styles.number)}>
        <StaticTextPropertyElement
          tag="span"
          identifier={{
            type: 'elementproperty',
            moduleId: '',
            elementId: '',
            property: '',
          }}
        >
          {getPageNumber(pageRank, cardSettings.pageNumbersStartAt)}
        </StaticTextPropertyElement>
      </span>
    </div>
  );
};
