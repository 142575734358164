import React from 'react';
import { PageFrameElementsWrapperProps } from './PageFrameElementsWrapperProps';
import * as styles from './staticPageFrameElementsWrapperStyles.module.scss';

export const StaticPageFrameElementsWrapper: React.FC<PageFrameElementsWrapperProps> = (
  props
) => {
  return (
    <div className={styles.wrapper}>
      {props.children({
        color: props.color,
        elementClassName: props.elementClassName,
        onMouseLeaveElement: props.onMouseLeaveElement,
        onMouseEnterElement: props.onMouseEnterElement,
        onClickElement: props.onClickElement,
      })}
    </div>
  );
};
