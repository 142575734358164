import React, { HTMLProps, useContext } from 'react';
import * as styles from './slotWrapperStyles.module.scss';
import { PageContext } from '../../../../contexts/page/PageContext';
import { mm } from '../../../../utils/measurements/mm';
import { getSpacing } from '../../../../utils/spacing/getSpacing';
import classnames from 'classnames';
import { SLOT_SPACING } from '../../../../utils/spacing/TSpacingSize';

type Props = HTMLProps<HTMLDivElement> & {
  verticalSpace: boolean;
  noSpaceTop?: boolean;
};

export const SlotWrapper: React.FC<Props> = (props) => {
  const { cardSettings } = useContext(PageContext);
  const { verticalSpace: verticalGap, noSpaceTop, ...divProps } = props;

  const margin = verticalGap
    ? mm(getSpacing(SLOT_SPACING, cardSettings.spaceScale) / 2)
    : undefined;

  return (
    <div
      {...divProps}
      style={{
        ...divProps.style,
        marginTop: noSpaceTop ? undefined : margin,
        marginBottom: margin,
      }}
      className={classnames(divProps.className, styles.slot)}
    />
  );
};
