import React from 'react';

export const SlotContext = React.createContext<{
  slotName: string;
  level: number;
  modulesInSlot: number;
  rank: number;
}>({
  level: 0,
  slotName: 'undefined',
  modulesInSlot: 0,
  rank: 0,
});
