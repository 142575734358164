import React, { CSSProperties, useContext, useMemo } from 'react';
import { PageSizesContext } from '../../../../../contexts/page/PageSizesContext';
import { mmValueToEm } from '../../../../../utils/measurements/mmValueToEm';
import { TDividerProps } from '../TDividerProps';
import * as styles from './lineDividerStyles.module.scss';

export const LineDivider: React.FC<TDividerProps> = ({ color }) => {
  const { baseFontSize } = useContext(PageSizesContext);

  const style = useMemo<CSSProperties>(() => {
    const barHeight = Math.max(1, Math.round(mmValueToEm(0.5) * baseFontSize));

    return {
      height: `${barHeight}px`,
      backgroundColor: color,
      marginTop: `-${barHeight / 2}px`,
      marginBottom: `-${barHeight / 2}px`,
    };
  }, [color, baseFontSize]);

  return <div style={style} className={styles.line} />;
};
