import { isEqualSize } from './isEqualSize';
import { TSize } from './TSize';

export const DIN_A4_PORTRAIT: TSize = {
  width: 210,
  height: 297,
};
export const DIN_A4_LANDSCAPE: TSize = {
  width: 297,
  height: 210,
};
export const DIN_A5_PORTRAIT: TSize = {
  width: 148,
  height: 210,
};
export const DIN_A5_LANDSCAPE: TSize = {
  width: 210,
  height: 148,
};

export const DIN_LONG: TSize = {
  width: 99,
  height: 210,
};

const DOCUMENT_FORMATS = [
  DIN_A4_PORTRAIT,
  DIN_A4_LANDSCAPE,
  DIN_A5_PORTRAIT,
  DIN_A5_LANDSCAPE,
  DIN_LONG,
];

export type TDocumentFormatKey =
  | 'din-a4-portrait'
  | 'din-a4-landscape'
  | 'din-a5-portrait'
  | 'din-a5-landscape'
  | 'din-long';

export type TDefinedDocumentFormatKey = Exclude<TDocumentFormatKey, 'custom'>;

export const DOCUMENT_FORMATS_MAP: Record<TDefinedDocumentFormatKey, TSize> = {
  'din-a4-portrait': DIN_A4_PORTRAIT,
  'din-a4-landscape': DIN_A4_LANDSCAPE,
  'din-a5-portrait': DIN_A5_PORTRAIT,
  'din-a5-landscape': DIN_A5_LANDSCAPE,
  'din-long': DIN_LONG,
};

export const DOCUMENT_FORMAT_NAMES_MAP: Record<
  TDefinedDocumentFormatKey,
  string
> = {
  'din-a4-portrait': 'DIN A4 (Hochformat: 21x29,7cm)',
  'din-a4-landscape': 'DIN A4 (Querformat: 29,7x21cm)',
  'din-a5-portrait': 'DIN A5 (Hochformat: 14,8x21cm)',
  'din-a5-landscape': 'DIN A5 (Querformat: 21x14,8cm)',
  'din-long': 'DIN lang (9,9x21cm)',
};

export const isA4Format = (value: TDocumentFormatKey | TSize) => {
  if (typeof value === 'string') {
    return value === 'din-a4-portrait' || value === 'din-a4-landscape';
  }
  const size = value as TSize;
  return (
    isEqualSize(size, DIN_A4_PORTRAIT) || isEqualSize(size, DIN_A4_LANDSCAPE)
  );
};

export const isCustomFormat = (size: TSize) =>
  !DOCUMENT_FORMATS.find((format) => isEqualSize(size, format));

export const getDocumentFormat = (key: TDefinedDocumentFormatKey) =>
  DOCUMENT_FORMATS_MAP[key];
