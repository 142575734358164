import axios, { AxiosResponse } from 'axios';
import { TPublishChannel } from '@card/core';
import { getConfig } from 'config/getConfig';

export const api = axios.create({
  baseURL: getConfig('PUBLISH_SERVICE_URL') + 'api/',
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${getConfig('PUBLISH_SERVICE_TOKEN')}`,
  },
});

const getData = <T>(res: AxiosResponse<T>) => res.data;

export const getPublishChannel = (id: string) =>
  api.get<TPublishChannel>(`embed/${id}`).then(getData);
