import { parseRGBA } from './parseRGBA';
import { TRGBAColor } from './TRGBAColor';
import { TRGBAColorString } from './TRGBAColorString';

export const isVisibleColor = (color: TRGBAColorString | TRGBAColor) => {
  let rgba: TRGBAColor | null;
  if (typeof color === 'string') {
    rgba = parseRGBA(color);
  } else {
    rgba = color;
  }

  return rgba && rgba.a > 0;
};
