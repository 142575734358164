import { TSlotName } from '../../components/card/slots/TSlotName';
import { IContentSlotDefinition } from './IContentSlotDefinition';
import { IGroupSlotDefinition } from './IGroupSlotDefinition';
import { IPageTemplate } from './IPageTemplate';

const content = (
  name: TSlotName,
  def?: Omit<IContentSlotDefinition, 'type' | 'name'>
): IContentSlotDefinition => ({ ...def, type: 'content', name });

const group = (
  name: TSlotName,
  def: Omit<IGroupSlotDefinition, 'type' | 'name'>
): IGroupSlotDefinition => ({ ...def, type: 'group', name });

const t = (p: IPageTemplate) => p;

export const pageTemplatesMap = {
  // COVER --------------------------
  cover0: t({
    id: '9175dd13-412d-45ea-9aff-aae154b97a11',
    slots: [
      content('top'),
      content('middle', {
        verticalAlignment: 'center',
        grow: true,
      }),
      content('bottom', {
        verticalAlignment: 'bottom',
      }),
    ],
  }),

  cover1: t({
    id: '82d16e8b-d9dc-4475-8d3b-8cae508dcc26',
    slots: [
      content('top', { grow: true }),
      content('bottom', {
        verticalAlignment: 'bottom',
      }),
    ],
  }),

  // 1 COLUMN --------------------------
  topBottom: t({
    id: 'dbe50670-3d22-4af6-8a03-0c3a798fae4e',
    hasFooter: true,
    slots: [
      content('top', { grow: true }),
      content('bottom', {
        verticalAlignment: 'bottom',
      }),
    ],
  }),

  topMiddleBottom: t({
    id: 'af21d241-c5c8-4f20-a2a4-700512dfabab',
    hasFooter: true,
    slots: [
      content('top'),
      content('middle', {
        verticalAlignment: 'center',
        grow: true,
      }),
      content('bottom'),
    ],
  }),

  // 2 COLUMNS --------------------------
  cols2: t({
    id: 'e64d6788-d2bf-44a0-b406-df8f5d0ce487',
    hasFooter: true,
    slots: [
      content('top'),
      group('middle', {
        grow: true,
        orientation: 'row',
        slots: [
          content('left', {
            grow: true,
            style: 'border-right',
          }),
          content('right', {
            grow: true,
          }),
        ],
      }),
      content('bottom', {
        verticalAlignment: 'bottom',
      }),
    ],
  }),

  cols2Middle2: t({
    id: '91df47b6-9b7b-4d3b-acb8-a9677c28a19e',
    hasFooter: true,
    slots: [
      content('top'),
      group('cols1', {
        grow: true,
        orientation: 'row',
        slots: [
          content('left', {
            grow: true,
            style: 'border-right',
          }),
          content('right', {
            grow: true,
          }),
        ],
      }),
      content('middle'),
      group('cols2', {
        grow: false,
        orientation: 'row',
        slots: [
          content('left', {
            grow: true,
            style: 'border-right',
          }),
          content('right', {
            grow: true,
          }),
        ],
      }),
      content('bottom', {
        verticalAlignment: 'bottom',
      }),
    ],
  }),

  cols2Bottom: t({
    id: '520d7973-29b0-479d-aee3-36c6be919497',
    hasFooter: true,
    slots: [
      content('top', {
        grow: true,
      }),
      content('bottomcolsheader'),
      group('bottomcols', {
        grow: false,
        orientation: 'row',
        slots: [
          content('left', {
            grow: true,
            style: 'border-right',
          }),
          content('right', {
            grow: true,
          }),
        ],
      }),
      content('bottom', {
        verticalAlignment: 'bottom',
      }),
    ],
  }),

  // 3 COLUMNS --------------------------
  cols3: t({
    id: '17decdfe-28a4-441e-ae63-fc003305f0e2',
    hasFooter: true,
    slots: [
      content('top'),
      group('middle', {
        grow: true,
        orientation: 'row',
        slots: [
          content('left', {
            grow: true,
            style: 'border-right',
          }),
          content('center', {
            grow: true,
          }),
          content('right', {
            grow: true,
            style: 'border-left',
          }),
        ],
      }),
      content('bottom', {
        verticalAlignment: 'bottom',
      }),
    ],
  }),

  cols3Bottom: t({
    id: 'f4ebf8b1-7a05-44ed-931c-ab24a0279d8d',
    hasFooter: true,
    slots: [
      content('top', {
        grow: true,
      }),
      content('bottomcolsheader'),
      group('bottomcols', {
        grow: false,
        orientation: 'row',
        slots: [
          content('left', {
            grow: true,
            style: 'border-right',
          }),
          content('center', {
            grow: true,
          }),
          content('right', {
            grow: true,
            style: 'border-left',
          }),
        ],
      }),
      content('bottom', {
        verticalAlignment: 'bottom',
      }),
    ],
  }),
};

export const DEFAULT_PAGE_TEMPLATE_ID = pageTemplatesMap.topMiddleBottom.id;

const pageTemplates = Object.values(pageTemplatesMap);

export const getPageTemplateById = (id: string) => {
  const template = pageTemplates.find((t) => t.id === id);

  if (!template) {
    throw new Error(`cannot find template for id ${id}`);
  }

  return template;
};

export const getDefaultPageTemplate = () =>
  getPageTemplateById(DEFAULT_PAGE_TEMPLATE_ID);
