import React, { useContext } from 'react';
import { PageContext } from '../../../../../../contexts/page/PageContext';
import { IDishElementData } from '../../../../../../data/elements/menu/IDishElementData';
import { getMenuVariantDefinition } from '../../../../../../utils/menu/getMenuVariantDefinition';
import { propertyOf } from '../../../../../../utils/typing/propertyOf';
import { StaticPricePropertyElement } from '../../../property/StaticPricePropertyElement/StaticPricePropertyElement';
import { DishPricePropertyElementProps } from './DishPricePropertyElementProps';

export const StaticDishPricePropertyElement: React.FC<DishPricePropertyElementProps> = (
  props
) => {
  const { cardSettings } = useContext(PageContext);
  const variantDefinition = getMenuVariantDefinition(cardSettings.menuVariant);

  return (
    <StaticPricePropertyElement
      tag="div"
      className={props.className}
      identifier={{
        type: 'groupitemproperty',
        moduleId: props.menuId,
        elementId: props.menuId,
        groupItemId: props.dishData.id,
        property: propertyOf<IDishElementData>('price'),
      }}
      textAlign={variantDefinition.alignment}
    >
      {props.dishData.price}
    </StaticPricePropertyElement>
  );
};
