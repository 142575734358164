import classnames from 'classnames';
import React, { ReactNode, useContext } from 'react';
import { PageContext } from '../../../../../../contexts/page/PageContext';
import { getMenuVariantDefinition } from '../../../../../../utils/menu/getMenuVariantDefinition';
import { TTextTagType } from '../../../../../../utils/text/TTextTagType';
import { StaticTextPropertyElement } from '../../../property/StaticTextPropertyElement/StaticTextPropertyElement';
import { DishNamePropertyElementProps } from './DishNamePropertyElementProps';
import * as styles from './staticDishNamePropertyElementStyles.module.scss';
import { wrapWordsInSpans } from './wrapWordsInSpans';

export const DISH_NAME_PROPERTY_ELEMENT_TAG: TTextTagType = 'h4';
export const DISH_NAME_PROPERTY_ELEMENT_WEIGHT = 'semibold';
export const DISH_NAME_PROPERTY_ELEMENT_DISPLAY_LEFT = 'inline-block';
export const DISH_NAME_PROPERTY_ELEMENT_DISPLAY_CENTER = 'block';
export const DISH_NAME_PROPERTY_ELEMENT_TRANSFORMATION = (
  children: ReactNode
): ReactNode => (
  <span
    className={styles.wordsWrapper}
    dangerouslySetInnerHTML={{
      __html: wrapWordsInSpans(`${children}`),
    }}
  />
);

export const StaticDishNamePropertyElement: React.FC<DishNamePropertyElementProps> = (
  props
) => {
  const { cardSettings } = useContext(PageContext);
  const variantDefinition = getMenuVariantDefinition(cardSettings.menuVariant);

  return (
    <StaticTextPropertyElement
      identifier={props.identifier}
      tag={DISH_NAME_PROPERTY_ELEMENT_TAG}
      weight={DISH_NAME_PROPERTY_ELEMENT_WEIGHT}
      className={classnames(props.className, {
        [styles.showDottedLine]: props.showDottedLine,
        [styles.center]: variantDefinition.alignment === 'center',
        [styles.tiny]: props.dottedLineWidth === 'tiny',
        [styles.normal]: props.dottedLineWidth === 'normal',
      })}
      display={
        variantDefinition.alignment === 'center'
          ? DISH_NAME_PROPERTY_ELEMENT_DISPLAY_CENTER
          : DISH_NAME_PROPERTY_ELEMENT_DISPLAY_LEFT
      }
      textAlign={variantDefinition.alignment}
      transformBeforeRender={DISH_NAME_PROPERTY_ELEMENT_TRANSFORMATION}
    >
      {props.dishData.name}
    </StaticTextPropertyElement>
  );
};
