import React, { CSSProperties, useContext, useMemo } from 'react';
import { PageSizesContext } from '../../../../../contexts/page/PageSizesContext';
import { mm } from '../../../../../utils/measurements/mm';
import { mmValueToEm } from '../../../../../utils/measurements/mmValueToEm';
import { TDividerProps } from '../TDividerProps';
import * as styles from './dotsDividerStyles.module.scss';

const items = [0, 1, 2, 3, 4, 5];
const sizeMM = 1;

export const DotsDivider: React.FC<TDividerProps> = ({ color }) => {
  const { baseFontSize } = useContext(PageSizesContext);

  const style = useMemo<CSSProperties>(() => {
    const sizePX = Math.ceil(mmValueToEm(sizeMM) * baseFontSize);
    const space = mm(1);

    return {
      width: `${sizePX}px`,
      height: `${sizePX}px`,
      backgroundColor: color,
      marginRight: space,
      marginLeft: space,
      marginTop: `-${Math.round(sizePX / 2)}px`,
      marginBottom: `-${Math.round(sizePX / 2)}px`,
    };
  }, [color, baseFontSize]);

  return (
    <div className={styles.wrapper}>
      {items.map((index) => (
        <div key={index} className={styles.dot} style={style} />
      ))}
    </div>
  );
};
