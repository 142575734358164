import { TBreakPointMap } from './utils/breakpoint/TBreakPointMap';
// https://material-ui.com/customization/breakpoints/
export const BREAKPOINTS: TBreakPointMap<number> = {
  XS: 0,
  S: 600,
  M: 960,
  L: 1280,
  XL: 1920,
};

export const COLORS = {
  LIGHT: '#f1f4fd',
  COLOR_IMAGE_BACKGROUND: '#f8faff',
  DARK: '#484f66',
  BODY_TEXT: '#959baf',
  YELLOW: '#ffbe58',
  LIGHT_YELLOW: '#ffbe5880',
  BLUE: '#4161d4',
  WHITE: '#ffffff',
  SUCCESS: '#115B07',
  WARNING: '#FFD800',
  ERROR: '#7A0C06',
};

export const BASE_UNIT = 4;
export const DEFAULT_ELEVATION = 2;

export const EDITOR_URL = '/editor/';
export const DASH_URL = '/dash/';
export const WIZARD_URL = '/wizard/';
export const ACCOUNT_URL = '/account/';
export const CHECKOUT_URL = '/checkout/';

export const WEBSITE_URL = 'https://www.foodcards.de';
export const WEBSITE_URL_TOS = 'https://www.foodcards.de/agb/';
export const WEBSITE_URL_PRIVACY_POLICY =
  'https://www.foodcards.de/datenschutzerklaerung/';
export const WEBSITE_URL_IMPRINT = 'https://www.foodcards.de/impressum/';
