import React, { useContext } from 'react';
import { PageContext } from '../../../../../contexts/page/PageContext';
import { getMenuVariantDefinition } from '../../../../../utils/menu/getMenuVariantDefinition';
import { TTextTagType } from '../../../../../utils/text/TTextTagType';
import { StaticTextPropertyElement } from '../../property/StaticTextPropertyElement/StaticTextPropertyElement';
import { MenuCategoryNamePropertyElementProps } from './MenuCategoryNamePropertyElementProps';

export const MENU_CATEGORY_NAME_TAG: TTextTagType = 'h3';

export const StaticMenuCategoryNamePropertyElement: React.FC<MenuCategoryNamePropertyElementProps> = (
  props
) => {
  const { cardSettings } = useContext(PageContext);
  const variantDefinition = getMenuVariantDefinition(cardSettings.menuVariant);

  return (
    <StaticTextPropertyElement
      identifier={props.identifier}
      tag={MENU_CATEGORY_NAME_TAG}
      className={props.className}
      textAlign={variantDefinition.alignment}
    >
      {props.menuCategoryData.name}
    </StaticTextPropertyElement>
  );
};
