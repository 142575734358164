import { TFont } from '../fonts/TFont';
import { pt } from './pt';
import { TTextSizeVariant } from './TTextSizeVariant';
import { TTextTagType } from './TTextTagType';

const factors: Record<TTextSizeVariant, number> = {
  // large: 1.5,
  medium: 1,
  small: 0.7,
};

const sizes: Record<TTextTagType, number> = {
  p: 10,
  div: 10,
  span: 10,
  h1: 37.5, // h2 * 1.5
  h2: 25,
  h3: 15,
  h4: 12,
};

export const getFontSize = (
  font: TFont,
  tag: TTextTagType,
  variant: TTextSizeVariant,
  scale: number
) => pt(sizes[tag] * factors[variant] * font.sizeFactor * scale);
