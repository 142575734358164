import classnames from 'classnames';
import React, { CSSProperties, useContext } from 'react';
import { PageContext } from '../../../../../contexts/page/PageContext';
import { getFontSetById } from '../../../../../utils/fonts/getFontSetById';
import { getLineHeightFix } from '../../../../../utils/fonts/getLineHeightFix';
import { getFontSize } from '../../../../../utils/text/getFontSize';
import { isEmptyText } from '../../../../../utils/text/isEmptyText';
import { TEXT_PLACEHOLDER } from '../../../../../utils/text/textPlaceholder/textPlaceholder';
import * as styles from './staticTextPropertyElementStyles.module.scss';
import { TextPropertyElementProps } from './TextPropertyElementProps';

export const StaticTextPropertyElement = React.forwardRef<
  HTMLDivElement,
  TextPropertyElementProps
>((props, ref) => {
  const { cardSettings, env } = useContext(PageContext);
  const fontSet = getFontSetById(cardSettings.fontSetId);
  const isHeadline = props.tag.indexOf('h') === 0;
  const font = isHeadline ? fontSet.headline : fontSet.text;

  const variant = props.variant || 'medium';
  const display = props.display || 'block';

  let weight: number | 'bold' = font.normalWeight;
  if (props.weight === 'semibold' && font.semiBoldWeight) {
    weight = font.semiBoldWeight;
  } else if (props.weight === 'bold') {
    weight = font.boldWeight;
  }

  const style: CSSProperties = {
    fontFamily: font.familyStack,
    fontWeight: weight,
    fontSize: getFontSize(font, props.tag, variant, cardSettings.scale),
    lineHeight: font.lineHeight + 'em',
    textAlign: props.textAlign,
    color:
      props.tag === 'h1' || props.tag === 'h2' || props.tag === 'h3'
        ? cardSettings.colors.headlines
        : cardSettings.colors.text,
    margin: `${getLineHeightFix(font.lineHeight)}em 0`,
    padding: 0,
  };

  const tagProps = {
    ref,
    style,
    className: classnames(props.className, {
      [styles.inline]: display === 'inline',
      [styles.inlineBlock]: display === 'inline-block',
      [styles.block]: display === 'block',
    }),
    onClick: props.onClick,
  };

  let children = props.transformBeforeRender
    ? props.transformBeforeRender(props.children)
    : props.children;

  const content =
    env === 'editor' &&
    typeof children === 'string' &&
    isEmptyText(children) ? (
      <span dangerouslySetInnerHTML={{ __html: TEXT_PLACEHOLDER }} />
    ) : (
      children
    );

  switch (props.tag) {
    case 'div':
      return <div {...tagProps}>{content}</div>;
    case 'span':
      return <span {...tagProps}>{content}</span>;
    case 'p':
      return <p {...tagProps}>{content}</p>;
    case 'h1':
      return <h1 {...tagProps}>{content}</h1>;
    case 'h2':
      return <h2 {...tagProps}>{content}</h2>;
    case 'h3':
      return <h3 {...tagProps}>{content}</h3>;
    case 'h4':
      return <h4 {...tagProps}>{content}</h4>;
  }
});
