import React, { useContext } from 'react';
import { BORDER_GAP_WIDTH } from '../../../../../constants';
import { PageContext } from '../../../../../contexts/page/PageContext';
import { showPageNumber } from '../../../../../utils/pagenumber/showPageNumber';
import { PageFrameProps } from '../AllPageFrame/PageFrameProps';
import { Border } from '../frameparts/Border/Border';

export const BoxPageFrame: React.FC<PageFrameProps> = (props) => {
  const { PageFrameElementsWrapper } = useContext(PageContext);
  const includePageNumberGap = showPageNumber(
    props.cardSettings.showPageNumbers,
    props.cardSettings.pageNumbersStartAt,
    props.pageRank
  );

  return (
    <PageFrameElementsWrapper color={props.cardSettings.colors.primary}>
      {({
        color,
        onMouseEnterElement,
        onMouseLeaveElement,
        onClickElement,
        elementClassName,
      }) => (
        <>
          <Border
            onMouseEnter={onMouseEnterElement}
            onMouseLeave={onMouseLeaveElement}
            onClick={onClickElement}
            className={elementClassName}
            position="top"
            color={color}
          />
          <Border
            onMouseEnter={onMouseEnterElement}
            onMouseLeave={onMouseLeaveElement}
            onClick={onClickElement}
            className={elementClassName}
            position="right"
            color={color}
          />

          <Border
            onMouseEnter={onMouseEnterElement}
            onMouseLeave={onMouseLeaveElement}
            onClick={onClickElement}
            className={elementClassName}
            position="bottom"
            color={color}
            gapWidth={includePageNumberGap ? BORDER_GAP_WIDTH : 0}
          />
          <Border
            onMouseEnter={onMouseEnterElement}
            onMouseLeave={onMouseLeaveElement}
            onClick={onClickElement}
            className={elementClassName}
            position="left"
            color={color}
          />
        </>
      )}
    </PageFrameElementsWrapper>
  );
};
