import { TMenuVariant } from './TMenuVariant';

type TMenuVariantDefinition = {
  lineToPrice: boolean;
  alignment: 'left' | 'center';
  pricePosition: 'with-title' | 'bottom' | 'none';
};

const menuVariantsMap: Record<TMenuVariant, TMenuVariantDefinition> = {
  'left-line-price': {
    alignment: 'left',
    lineToPrice: true,
    pricePosition: 'with-title',
  },
  'left-no-line-price': {
    alignment: 'left',
    lineToPrice: false,
    pricePosition: 'with-title',
  },
  'left-no-price': {
    alignment: 'left',
    lineToPrice: false,
    pricePosition: 'none',
  },
  'center-price-bottom': {
    alignment: 'center',
    lineToPrice: false,
    pricePosition: 'bottom',
  },
  'center-no-price': {
    alignment: 'center',
    lineToPrice: false,
    pricePosition: 'none',
  },
};

export const getMenuVariantDefinition = (variant: TMenuVariant) =>
  menuVariantsMap[variant];
