import React, { useMemo } from 'react';
import { StaticDishDescriptionPropertyElement } from '../../components/card/elements/menu/dish/StaticDishDescriptionPropertyElement/StaticDishDescriptionPropertyElement';
import { StaticDishElement } from '../../components/card/elements/menu/dish/StaticDishElement/StaticDishElement';
import { StaticDishNamePropertyElement } from '../../components/card/elements/menu/dish/StaticDishNamePropertyElement/StaticDishNamePropertyElement';
import { StaticDishPricePropertyElement } from '../../components/card/elements/menu/dish/StaticDishPricePropertyElement/StaticDishPricePropertyElement';
import { StaticMenuCategoryNamePropertyElement } from '../../components/card/elements/menu/StaticMenuCategoryNamePropertyElement/StaticMenuCategoryNamePropertyElement';
import { StaticImageElement } from '../../components/card/elements/StaticImageElement/StaticImageElement';
import { StaticRichTextElement } from '../../components/card/elements/StaticRichTextElement/StaticRichTextElement';
import { StaticDishesWrapper } from '../../components/card/modules/MenuModule/StaticDishesWrapper';
import { StaticModuleWrapper } from '../../components/card/modules/StaticModuleWrapper/StaticModuleWrapper';
import { StaticPageFrameElementsWrapper } from '../../components/card/page/pageframes/StaticPageFrameElementsWrapper/StaticPageFrameElementsWrapper';
import { StaticAdvertisement } from '../../components/card/page/StaticAdvertisement/StaticAdvertisement';
import { StaticPage } from '../../components/card/page/StaticPage/StaticPage';
import { StaticPageBackground } from '../../components/card/page/StaticPageBackground/StaticPageBackground';
import { StaticPageNumber } from '../../components/card/page/StaticPageNumber/StaticPageNumber';
import { StaticSlotBorder } from '../../components/card/slots/borders/StaticSlotBorder/StaticSlotBorder';
import { PlainSlot } from '../../components/card/slots/PlainSlot/PlainSlot';
import { StaticEmptySlotContent } from '../../components/card/slots/StaticEmptySlotContent/StaticEmptySlotContent';
import { StaticSlotContentWrapper } from '../../components/card/slots/StaticSlotContentWrapper/StaticSlotContentWrapper';
import { StaticFooter } from '../../components/card/StaticFooter/StaticFooter';
import { CardContext } from './CardContext';
import { TCardContext } from './TCardContext';

export type TStaticComponents =
  | 'RichTextElement'
  | 'MenuCategoryNamePropertyElement'
  | 'DishNamePropertyElement'
  | 'DishDescriptionPropertyElement'
  | 'DishPricePropertyElement'
  | 'ModuleWrapper'
  | 'DishElement'
  | 'PlainSlot'
  | 'EmptySlotContent'
  | 'DishesWrapper'
  | 'SlotContentWrapper'
  | 'Footer'
  | 'PageNumber'
  | 'PageBackground'
  | 'PageFrameElementsWrapper'
  | 'ImageElement'
  | 'SlotBorder'
  | 'Advertisement'
  | 'Page';

const staticComponents: Pick<TCardContext, TStaticComponents> = {
  RichTextElement: StaticRichTextElement,
  MenuCategoryNamePropertyElement: StaticMenuCategoryNamePropertyElement,
  DishElement: StaticDishElement,
  DishNamePropertyElement: StaticDishNamePropertyElement,
  DishDescriptionPropertyElement: StaticDishDescriptionPropertyElement,
  DishPricePropertyElement: StaticDishPricePropertyElement,
  DishesWrapper: StaticDishesWrapper,
  ModuleWrapper: StaticModuleWrapper,
  PlainSlot,
  EmptySlotContent: StaticEmptySlotContent,
  SlotContentWrapper: StaticSlotContentWrapper,
  Footer: StaticFooter,
  PageNumber: StaticPageNumber,
  PageBackground: StaticPageBackground,
  PageFrameElementsWrapper: StaticPageFrameElementsWrapper,
  ImageElement: StaticImageElement,
  SlotBorder: StaticSlotBorder,
  Advertisement: StaticAdvertisement,
  Page: StaticPage,
};

type Props = {
  value: Omit<TCardContext, TStaticComponents>;
  components?: Partial<Pick<TCardContext, TStaticComponents>>;
};

export const StaticComponentsCardContext: React.FC<Props> = (props) => {
  const context: TCardContext = useMemo(
    () => ({ ...props.value, ...staticComponents, ...props.components }),
    [props.components, props.value]
  );

  return (
    <CardContext.Provider value={context}>
      {props.children}
    </CardContext.Provider>
  );
};
