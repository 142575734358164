import { TPublishChannel } from '@card/core';
import { TInitMessage } from 'iframe-client/serviceClient/TInitMessage';
import { TSetHeightMessage } from 'iframe-client/serviceClient/TSetHeightMessage';

export const initParent = (channel: TPublishChannel, height: number) => {
  const message: TInitMessage = {
    type: 'init',
    channelId: channel.id,
    css: channel.css,
    height,
  };
  window.parent.postMessage(JSON.stringify(message), channel.url);
};

export const emmitHeight = (channel: TPublishChannel, height: number) => {
  const message: TSetHeightMessage = {
    type: 'setHeight',
    channelId: channel.id,
    height,
  };
  window.parent.postMessage(JSON.stringify(message), channel.url);
};
