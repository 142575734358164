// https://stackoverflow.com/questions/51851677/how-to-get-argument-types-from-function-in-typescript
type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any
  ? A
  : never;

export class SubscriberList<
  T extends Record<string, (...args: any[]) => void>
> {
  private callbacks: {
    [event: string]: ((...args: any[]) => void)[];
  };

  constructor() {
    // @ts-ignore
    this.callbacks = {};
  }

  on = <K extends keyof T>(event: K, callback: T[K]) => {
    const key = event as string;
    this.callbacks[key] = this.callbacks[key] || [];
    // @ts-ignore
    this.callbacks[key].push(callback);

    const unsubscribe = () => {
      this.callbacks[key] = this.callbacks[key].filter((cb) => cb !== callback);
    };
    return unsubscribe;
  };

  notify = <K extends keyof T>(event: K, ...params: ArgumentTypes<T[K]>) => {
    const key = event as string;

    if (this.callbacks[key]) {
      this.callbacks[key].forEach((callback) => callback(...params));
    }
  };
}
