import { EmbedCard } from '../utils/EmbedCard';
import { TMessage } from './TMessage';

export const init = (getEmbedCard: (channelId: string) => EmbedCard | null) => {
  window.addEventListener(
    'message',
    function (ev) {
      if (typeof ev.data === 'string') {
        try {
          var message: TMessage = JSON.parse(ev.data);
          var embedCard = getEmbedCard(message.channelId);
          if (!embedCard) {
            return;
          }

          switch (message.type) {
            case 'init': {
              if (message.css) {
                embedCard.setCss(message.css);
              }
              embedCard.setHeight(message.height);
              break;
            }
            case 'setHeight': {
              embedCard.setHeight(message.height);
              break;
            }
            case 'setCss': {
              if (message.css) {
                embedCard.setCss(message.css);
              }
              break;
            }
          }
        } catch (e) {
          // ignore
        }
      }
    },
    false
  );
};
