import React, { useContext } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { RatioContainer } from '../../../utils/RatioContainer/RatioContainer';

type Props = {
  overflowHidden?: boolean;
};

export const PageContainer: React.FC<Props> = (props) => {
  const { cardSettings } = useContext(PageContext);

  return (
    <RatioContainer
      ratio={cardSettings.width / cardSettings.height}
      flex
      overflowHidden={props.overflowHidden}
    >
      {props.children}
    </RatioContainer>
  );
};
