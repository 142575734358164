import React, { useContext } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { TImageElementData } from '../../../../data/elements/TImageElementData';
import { getElementData } from '../../../../utils/elements/getElementData';
import { PageBackgroundProps } from './PageBackgroundProps';
import * as styles from './pageBackgroundStyles.module.scss';

export const StaticPageBackground: React.FC<PageBackgroundProps> = (props) => {
  const { getImgSrc, elements, cardSettings, env } = useContext(PageContext);

  const backgroundImage = getElementData<TImageElementData>(
    elements,
    props.pageData.backgroundImageId
  );

  const backgroundImageUrl = backgroundImage
    ? getImgSrc({
        image: backgroundImage,
        cardSize: cardSettings,
        env,
      })
    : undefined;

  return (
    <div className={styles.container}>
      <div
        className={styles.background}
        style={{
          backgroundColor: cardSettings.colors.background,
        }}
      >
        {backgroundImageUrl && (
          <img
            className={styles.backgroundImage}
            src={backgroundImageUrl}
            alt=""
          />
        )}
      </div>
      {props.children}
    </div>
  );
};
