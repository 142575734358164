import { TConfig } from '../TConfig';

export const config: TConfig = {
  ENV: 'prod',
  USER_MEDIA_URL: 'https://ik.imagekit.io/5gznnawhlz/media/',
  PUBLISH_SERVICE_URL: 'https://publish.service.foodcards.de/',
  PUBLISH_SERVICE_TOKEN: '1|CEZJbgzzD0Im2NnlPvG0SVhG4UcmgOq4fg2oOtX5',
  DEMO_CHANNEL_ID: '6918e3db-63a3-4dd0-9426-d78220c9f4e3',
  IMAGE_PLACEHOLDER_ID: '8883d0ab-e294-4854-a8f8-74444991bdc7',
  IMAGE_PLACEHOLDER_URI: '45wsYP2VX9T3fNQ8UxxCJmBESchr6FxBgPN9sZJL.png',
  IMAGE_PLACEHOLDER_MOZARELLA_STICKS_ID: 'd3494e4c-ceba-4c50-8bf9-7521855398b3',
  IMAGE_PLACEHOLDER_MOZARELLA_STICKS_URI:
    'Rg689thNFPTKDesMYQLIFjLaBbVpbZpHOMfald8s.jpeg',
  IMAGE_PLACEHOLDER_CREME_BRULEE_ID: 'b60cc301-7166-4637-9c64-b5b4db40f121',
  IMAGE_PLACEHOLDER_CREME_BRULEE_URI:
    '11asIjJTl4FQtVHpWIrVIimhPpmtKDiQX45MSxXf.jpeg',
  IMAGE_PLACEHOLDER_SALAT_ID: 'e5323878-392e-42a7-a2b0-84ea889695fd',
  IMAGE_PLACEHOLDER_SALAT_URI: 'fDFQl4mUWfwIoYdGYR5YMUPZfrQUXLAAkWzjo3Ib.jpeg',
  SENTRY_DSN:
    'https://839a9ee3fa3340c7838078e0e150ba75@o927820.ingest.sentry.io/5877500',
  SENTRY_SAMPLE_RATE: 0.1,
};
