import { ModuleProps } from '../../components/card/modules/ModuleProps';
import { ModuleWrapperProps } from '../../components/card/modules/StaticModuleWrapper/ModuleWrapperProps';

export const getModuleWrapperProps = ({
  className,
  onClick,
  children,
  moduleData,
}: ModuleProps): ModuleWrapperProps => ({
  className,
  onClick,
  children,
  moduleData,
});
