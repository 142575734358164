export const BASE_UNIT = 4;

export const BASE_DINA4_WIDTH_PX = 1190;

export const EMPTY_TEXT = '<p><br></p>';

// use own font proxy instead of https://fonts.googleapis.com/css2
export const GOOGLE_FONTS_API = 'https://fonts.foodcards.de/css2';
export const CSS_CLASS_PREFIX = 'card';

export const MIN_BORDER_WIDTH = 0.25;
export const MAX_BORDER_WIDTH = 3;
export const DEFAULT_BORDER_WIDTH = MIN_BORDER_WIDTH;
export const BORDER_GAP_WIDTH = 12;
export const DEFAULT_PAGE_MARGIN = 12;
export const MIN_PAGE_MARGIN = 5;
export const MAX_PAGE_MARGIN = 50;
export const MAX_SPACING = 2;
export const MIN_SPACING = 0;
export const MIN_FONT_SIZE = 0.6;
export const MAX_FONT_SIZE = 1.4;

export const MIN_IMAGE_SCALE = 0.05;
export const MAX_IMAGE_SCALE = 1;
