import React, { useContext } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { fillPlaceholderIntoEmptyText } from '../../../../utils/text/fillPlaceholderIntoEmptyText';
import { isEmptyText } from '../../../../utils/text/isEmptyText';
import { RichText } from '../../../utils/RichText/RichText';

type Props = {
  text: string;
  onClick?: (ev: React.MouseEvent<HTMLElement>) => void;
  className?: string;
};

export const BaseRichText: React.FC<Props> = (props) => {
  const { env } = useContext(PageContext);

  const text =
    env === 'editor' && isEmptyText(props.text)
      ? fillPlaceholderIntoEmptyText(props.text)
      : props.text;

  return (
    <RichText className={props.className} text={text} onClick={props.onClick} />
  );
};
