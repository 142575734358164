export type TSpacingSize = 'XS' | 'S' | 'S-Condensed' | 'M' | 'L';

export const SLOT_SPACING: TSpacingSize = 'L';
export const SLOT_SPACING_FOOTER: TSpacingSize = 'L';
export const MODULE_SPACING: TSpacingSize = 'L';
export const SLOT_COLUMN_SPACING: TSpacingSize = 'M';

export const MENU_CATEGORY_TITLE_SPACING: TSpacingSize = 'M';
export const DISH_DESCRIPTION_TOP_SPACING: TSpacingSize = 'XS';
export const DISH_SPACING: TSpacingSize = 'M';
// we have to used the condensed style here
// because otherwise the space between the description and the price is to similar
// in relation to the space between the price and the next dish
// one will not recognize any more if the price if for the above or underneath dish
export const DISH_PRICE_TOP_SPACING: TSpacingSize = 'S-Condensed';

export const TEXT_BOTTOM_SPACING: TSpacingSize = 'S';
export const TEXT_LIST_LEFT_SPACING: TSpacingSize = 'S';

export const TEXT_H1_TOP_SPACING: TSpacingSize = 'L';
export const TEXT_H1_BOTTOM_SPACING: TSpacingSize = 'L';

export const TEXT_H2_TOP_SPACING: TSpacingSize = 'L';
export const TEXT_H2_BOTTOM_SPACING: TSpacingSize = 'M';

export const TEXT_H3_4_TOP_SPACING: TSpacingSize = 'M';
export const TEXT_H3_4_BOTTOM_SPACING: TSpacingSize = 'S';
