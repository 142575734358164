import React from 'react';

type TPageSizesContext = {
  width: number;
  baseFontSize: number;
};

export const PageSizesContext = React.createContext<TPageSizesContext>({
  baseFontSize: 1,
  width: 1,
});
