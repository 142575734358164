import classnames from 'classnames';
import React, { useContext } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { mm } from '../../../../utils/measurements/mm';
import { getSpacing } from '../../../../utils/spacing/getSpacing';
import { MODULE_SPACING } from '../../../../utils/spacing/TSpacingSize';
import * as styles from './staticSlotContentWrapperStyles.module.scss';
import { TSlotContentWrapperProps } from './TSlotContentWrapperProps';

export const StaticSlotContentWrapper = React.forwardRef<
  HTMLDivElement,
  TSlotContentWrapperProps
>(({ slotDefinition, className, children, isEmpty, ...divProps }, ref) => {
  const { cardSettings } = useContext(PageContext);
  const spacing = mm(-getSpacing(MODULE_SPACING, cardSettings.spaceScale) / 2);

  return (
    <div
      {...divProps}
      ref={ref}
      className={classnames(className, styles.slotContentWrapperOuter)}
    >
      <div
        style={{
          marginTop: isEmpty ? undefined : spacing,
          marginBottom: isEmpty ? undefined : spacing,
        }}
        className={classnames(styles.slotContentWrapperInner, {
          [styles.verticalAlignTop]: slotDefinition.verticalAlignment === 'top',
          [styles.verticalAlignCenter]:
            slotDefinition.verticalAlignment === 'center',
          [styles.verticalAlignBottom]:
            slotDefinition.verticalAlignment === 'bottom',
        })}
      >
        {children}
      </div>
    </div>
  );
}) as React.ComponentType<TSlotContentWrapperProps>;
