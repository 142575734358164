import * as Sentry from '@sentry/react';
import { App } from 'components/App/App';
import { getConfig } from 'config/getConfig';
import React from 'react';
import ReactDOM from 'react-dom';

Sentry.init({
  dsn: getConfig('SENTRY_DSN'),

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: getConfig('SENTRY_SAMPLE_RATE'),
  environment: getConfig('ENV'),
});

ReactDOM.render(<App />, document.getElementById('root'));
