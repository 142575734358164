import { TElementData } from '../../data/elements/TElementData';
import { TElementDataMap } from '../../data/TElementDataMap';

export const elementsToMap = <T extends TElementData>(
  elements: T[] | null | undefined
): TElementDataMap<T> => {
  if (!elements) {
    return {};
  }

  return elements.reduce<TElementDataMap<T>>(
    (map, element) => ({ ...map, [element.id]: element }),
    {}
  );
};
