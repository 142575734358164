import { getConfig } from 'config/getConfig';
import React, { useEffect } from 'react';
import * as styles from './demoStyles.module.scss';

export const Demo: React.FC = (props) => {
  useEffect(() => {
    const client = require('../../iframe-client/iframe-client-v1');
    client.on('resize', (channelId: string, height: number) => {
      console.log('resize', channelId, height);
    });
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.navigation} />

      <div>
        <div data-foodcards-embed-channel={getConfig('DEMO_CHANNEL_ID')} />
      </div>

      <div className={styles.footer} />
    </div>
  );
};
