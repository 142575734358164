import { BREAKPOINTS } from '../../constants';
import { TBreakPoint } from './TBreakpoint';

export const getBreakpoint = (viewportWidth: number): TBreakPoint => {
  if (viewportWidth < BREAKPOINTS.S) {
    return TBreakPoint.XS;
  }
  if (viewportWidth < BREAKPOINTS.M) {
    return TBreakPoint.S;
  }
  if (viewportWidth < BREAKPOINTS.L) {
    return TBreakPoint.M;
  }
  if (viewportWidth < BREAKPOINTS.XL) {
    return TBreakPoint.L;
  }

  return TBreakPoint.XL;
};
