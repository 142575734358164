const global = window as any;
global.dataLayer = global.dataLayer || [];

export const createTracker = (category: string) => ({
  trackEvent(action: string, label?: string, value?: number) {
    global.dataLayer.push({
      event: 'ga',
      category,
      action,
      label,
      value,
    });
  },
});
