import classnames from 'classnames';
import React, { useContext } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { mm } from '../../../../utils/measurements/mm';
import { getSpacing } from '../../../../utils/spacing/getSpacing';
import { DISH_SPACING } from '../../../../utils/spacing/TSpacingSize';
import * as styles from './staticDishesWrapperStyles.module.scss';
import { TDishesWrapperProps } from './TDishesWrapperProps';

export const StaticDishesWrapper = React.forwardRef<
  HTMLDivElement,
  TDishesWrapperProps
>(({ menuCategoryData, moduleData, style, className, ...props }, ref) => {
  const { cardSettings } = useContext(PageContext);

  const spacing = mm(
    -getSpacing(DISH_SPACING, cardSettings.scale * cardSettings.spaceScale) / 2
  );

  return (
    <div
      {...props}
      className={classnames(className, styles.wrapper)}
      ref={ref}
      style={{
        ...style,
        marginTop: spacing,
        marginBottom: spacing,
      }}
    />
  );
}) as React.ComponentType<TDishesWrapperProps>;
