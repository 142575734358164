import React from 'react';
import * as styles from './pageContentWrapperStyles.module.scss';
import { mm } from '../../../../utils/measurements/mm';
import { PageContext } from '../../../../contexts/page/PageContext';

export const PageContentWrapper: React.FC = (props) => (
  <PageContext.Consumer>
    {({ cardSettings }) => (
      <div
        className={styles.wrapper}
        style={{
          marginTop: mm(cardSettings.pageMarginTop),
          marginRight: mm(cardSettings.pageMarginRight),
          marginBottom: mm(cardSettings.pageMarginBottom),
          marginLeft: mm(cardSettings.pageMarginLeft),
        }}
      >
        {props.children}
      </div>
    )}
  </PageContext.Consumer>
);
