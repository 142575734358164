import React, { useContext, useMemo } from 'react';
import { getPageTemplateById } from '../../creation/pageTemplates/pageTemplates';
import { CardContext } from '../card/CardContext';
import { PageContext } from './PageContext';
import { TPageContext } from './TPageContext';

type Props = {
  pageId: string;
  pageRank: number;
  pageTemplateId: string;
};
export const PageContextProvider: React.FC<Props> = ({
  children,
  pageId,
  pageRank,
  pageTemplateId,
}) => {
  const cardContext = useContext(CardContext);
  const pageContext = useMemo<TPageContext>(
    () => ({
      ...cardContext,
      pageId: pageId,
      pageRank: pageRank,
      pageTemplate: getPageTemplateById(pageTemplateId),
    }),
    [cardContext, pageId, pageRank, pageTemplateId]
  );

  return <PageContext.Provider value={pageContext} children={children} />;
};
