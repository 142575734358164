import { useContext } from 'react';
import { PageContext } from '../contexts/page/PageContext';
import { TElementData } from '../data/elements/TElementData';
import { getElementData } from '../utils/elements/getElementData';

export const useElementData = <T extends TElementData>(
  reference: string | null | undefined
) => {
  const { elements } = useContext(PageContext);
  return getElementData<T>(elements, reference);
};
