import React from 'react';
import { BarMenuTitleDecoration } from './BarMenuTitleDecoration/BarMenuTitleDecoration';
import { DoubleLineMenuTitleDecoration } from './DoubleLineMenuTitleDecoration/DoubleLineMenuTitleDecoration';
import { MenuTitleDecorationProps } from './MenuTitleDecorationProps';
import { MenuTitleDecorationType } from './MenuTitleDecorationType';
import { UnderlineMenuTitleDecoration } from './UnderlineMenuTitleDecoration/UnderlineMenuTitleDecoration';

const decoration: Record<
  Exclude<MenuTitleDecorationType, MenuTitleDecorationType.None>,
  React.ComponentType<MenuTitleDecorationProps>
> = {
  [MenuTitleDecorationType.Underline]: UnderlineMenuTitleDecoration,
  [MenuTitleDecorationType.Bar]: BarMenuTitleDecoration,
  [MenuTitleDecorationType.DoubleLine]: DoubleLineMenuTitleDecoration,
};

export const MenuTitleDecoration: React.FC<MenuTitleDecorationProps> = (
  props
) => {
  if (props.decorationType === MenuTitleDecorationType.None) {
    return <>{props.children}</>;
  }

  const Decoration = decoration[props.decorationType];
  return <Decoration {...props} />;
};
