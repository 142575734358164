import React, { useContext } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { TMenuCategoryElementData } from '../../../../data/elements/menu/TMenuCategoryElementData';
import { IMenuModuleData } from '../../../../data/modules/IMenuModuleData';
import { useElementData } from '../../../../hooks/useElementData';
import { getModuleWrapperProps } from '../../../../utils/modules/getModuleWrapperProps';
import { ModuleProps } from '../ModuleProps';
import { MenuTitle } from './MenuTitle/MenuTitle';

export const MenuModule: React.FC<ModuleProps<IMenuModuleData>> = (props) => {
  const { DishElement, ModuleWrapper, DishesWrapper } = useContext(PageContext);
  const menuCategoryData = useElementData<TMenuCategoryElementData>(
    props.moduleData.references.menuCategoryId
  )!;

  return (
    <ModuleWrapper
      {...getModuleWrapperProps(props)}
      applyHorizontalInnerSpacing={false}
    >
      {props.moduleData.settings.visibility.title && (
        <MenuTitle moduleData={props.moduleData} />
      )}

      <DishesWrapper
        menuCategoryData={menuCategoryData}
        moduleData={props.moduleData}
      >
        {menuCategoryData.dishes.map((dish, index) => (
          <DishElement
            moduleData={props.moduleData}
            key={dish.id}
            menuCategoryData={menuCategoryData}
            dishData={dish}
          />
        ))}
      </DishesWrapper>
    </ModuleWrapper>
  );
};
