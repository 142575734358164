import { findColor } from './findColor';
import { findUUID } from './findUUID';

type Props = {
  channelId: string;
  color: string | null;
};

type DataSet = {
  foodcardsEmbedChannel?: string;
  foodcardsEmbedColor?: string;
};

export const getRootElementProps = (element: Element) => {
  const dataset: DataSet = (element as any).dataset || {};

  const channelId =
    dataset.foodcardsEmbedChannel || findUUID(element.className);
  if (!channelId) {
    return null;
  }

  const color = (
    dataset.foodcardsEmbedColor ||
    findColor(element.className) ||
    ''
  ).replace('#', '');

  const props: Props = {
    channelId,
    color,
  };

  return props;
};
