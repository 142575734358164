import { init } from './serviceClient/cardServiceClient';
import { EmbedCard } from './utils/EmbedCard';
import { getRootElementProps } from './utils/getRootElementProps';
import { ready } from './utils/ready';
import { SubscriberList } from '@shared/utils';

const subscriberList = new SubscriberList<{
  resize: (channelId: string, height: number) => void;
}>();

export const on = subscriberList.on;

(() => {
  var channelsCardMap: Record<string, EmbedCard> = {};

  init((channelId) => channelsCardMap[channelId] || null);

  ready(() => {
    const elements = [
      ...Array.from(document.querySelectorAll('.foodcards-embed')),
      ...Array.from(
        document.querySelectorAll('[data-foodcards-embed-channel]')
      ),
    ] as HTMLElement[];
    elements.forEach((element) => {
      const props = getRootElementProps(element);
      if (!props) {
        return;
      }
      if (
        props.channelId ===
        '72aa6666-9958-49e4-bec4-9b7144407280' /* cafe bohne */
      ) {
        props.color = '73011e';
      }
      const embedCard = new EmbedCard(element, props.channelId, props);
      embedCard.onResize((height) =>
        subscriberList.notify('resize', props.channelId, height)
      );

      channelsCardMap[props.channelId] = embedCard;
    });
  });
})();
