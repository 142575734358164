import classnames from 'classnames';
import React from 'react';
import { useBorderSize } from '../../../../../../hooks/useBorderSize';
import { TRGBAColorString } from '../../../../../../utils/color/TRGBAColorString';
import { mm } from '../../../../../../utils/measurements/mm';
import * as styles from './borderStyles.module.scss';

type Props = {
  position: 'top' | 'right' | 'bottom' | 'left';
  gapWidth?: number;
  color: TRGBAColorString;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  className?: string;
};
export const Border: React.FC<Props> = (props) => {
  const orientation =
    props.position === 'left' || props.position === 'right'
      ? 'vertical'
      : 'horizontal';

  const style: React.CSSProperties = {
    backgroundColor: props.color,
  };

  const size = useBorderSize();
  if (orientation === 'horizontal') {
    style.height = size;
  } else {
    style.width = size;
  }
  return (
    <>
      {!props.gapWidth && (
        <div
          className={classnames(props.className, styles.border, {
            [styles.top]: props.position === 'top',
            [styles.right]: props.position === 'right',
            [styles.bottom]: props.position === 'bottom',
            [styles.left]: props.position === 'left',
            [styles.fullWidth]: orientation === 'horizontal',
          })}
          style={style}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          onClick={props.onClick}
        />
      )}

      {!!props.gapWidth && (
        <>
          <div
            className={classnames(
              props.className,
              styles.border,
              styles.splitLeft,
              {
                [styles.top]: props.position === 'top',
                [styles.bottom]: props.position === 'bottom',
              }
            )}
            style={{
              ...style,
              width: `calc(50% - ${mm(props.gapWidth / 2)})`,
            }}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onClick={props.onClick}
          />
          <div
            className={classnames(
              props.className,
              styles.border,
              styles.splitRight,
              {
                [styles.top]: props.position === 'top',
                [styles.bottom]: props.position === 'bottom',
              }
            )}
            style={{
              ...style,
              width: `calc(50% - ${mm(props.gapWidth / 2)})`,
            }}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onClick={props.onClick}
          />
        </>
      )}
    </>
  );
};
