import classnames from 'classnames';
import React from 'react';
import { IGroupSlotDefinition } from '../../../../creation/pageTemplates/IGroupSlotDefinition';
import { TSlotDataMap } from '../../../../data/TSlotDataMap';
import { Slots } from '../Slots/Slots';
import { SlotWrapper } from '../SlotWrapper/SlotWrapper';
import { TSlotName } from '../TSlotName';
import * as styles from './groupSlotStyles.module.scss';

type Props = {
  pageId: string;
  pageRank: number;
  slotsData: TSlotDataMap;
  slotDefinition: IGroupSlotDefinition;
  parentSlotNames: TSlotName[];
};

export const GroupSlot: React.FC<Props> = (props) => {
  const slotNames = [...props.parentSlotNames, props.slotDefinition.name];
  return (
    <SlotWrapper
      verticalSpace
      className={classnames({
        [styles.row]: props.slotDefinition.orientation === 'row',
        [styles.col]: props.slotDefinition.orientation === 'column',
        [styles.grow]: props.slotDefinition.grow,
      })}
    >
      <Slots
        slotsData={props.slotsData}
        pageId={props.pageId}
        pageRank={props.pageRank}
        slotDefinitions={props.slotDefinition.slots}
        parentSlotNames={slotNames}
      />
    </SlotWrapper>
  );
};
