import { TConfig } from '../TConfig';

export const config: TConfig = {
  ENV: 'dev',
  USER_MEDIA_URL: 'https://card-service.test/storage/media/',
  PUBLISH_SERVICE_URL: 'https://publish-service.test/',
  PUBLISH_SERVICE_TOKEN: '4|DSPDlO3fOxa7zmH35R6EUm2EficUEzwNWaHA2F0N',
  DEMO_CHANNEL_ID: 'a4ea6925-195d-4fdf-8533-fbec2218d54f',
  IMAGE_PLACEHOLDER_ID: '48e59159-1898-4f4b-8388-7a27e3a57b5c',
  IMAGE_PLACEHOLDER_URI: 'JMOADtLhZXSHc2pdte8FkLzoikymfyYBxf3jqbi7.png',
  IMAGE_PLACEHOLDER_MOZARELLA_STICKS_ID: 'b2ae55a4-10ed-49fa-ba6d-1901d9971bb1',
  IMAGE_PLACEHOLDER_MOZARELLA_STICKS_URI:
    '5InFYSMAHbLlW3VJohXC7maXFfZbUiEZmvoCyeFj.jpeg',
  IMAGE_PLACEHOLDER_CREME_BRULEE_ID: 'be69c803-abab-4cd6-b3f8-80db170104ea',
  IMAGE_PLACEHOLDER_CREME_BRULEE_URI:
    'Y0H01Oy7RqWHPhFSpJVLeCzN9wY6pw8ls1VTNxqF.jpeg',
  IMAGE_PLACEHOLDER_SALAT_ID: '11e51420-460c-4859-938d-40172f9e1b98',
  IMAGE_PLACEHOLDER_SALAT_URI: 'zrrYD4AEMvM8Nom3Si8SrM8DT1F0woRsS12Yoimi.jpeg',
  SENTRY_DSN:
    'https://839a9ee3fa3340c7838078e0e150ba75@o927820.ingest.sentry.io/5877500',
  SENTRY_SAMPLE_RATE: 0.1,
};
