export const findColor = (text: string) => {
  const regex = /foodcards-embed-color-(\w+)(\s+.*|$)/;
  const result = text.match(regex);

  if (!result) {
    return null;
  }

  return result[1];
};
