import { TElementData } from '../../data/elements/TElementData';
import { TElementDataMap } from '../../data/TElementDataMap';
import { UUID } from '../UUID';

export const getElementData = <T extends TElementData>(
  elements: TElementDataMap,
  elementId: UUID | null | undefined
) => {
  if (!elementId) {
    return null;
  }

  const element = elements[elementId];
  if (element) {
    return element as T;
  }

  return null;
};
