import classnames from 'classnames';
import React, { useContext } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { RatioContainer } from '../../../utils/RatioContainer/RatioContainer';
import { ImageElementAlignment } from './ImageElementAlignment';
import { ImageElementFormat } from './ImageElementFormat';
import { ImageElementProps } from './ImageElementProps';
import * as styles from './staticImageElementStyles.module.scss';

export const StaticImageElement: React.FC<ImageElementProps> = ({
  imageElement: image,
  format,
  scale,
  onClick,
  children,
  className,
  imageClassName,
  imageWrapperClassName,
  alignment,
}) => {
  const { getImgSrc, env, cardSettings } = useContext(PageContext);

  let ratio: number;
  switch (format) {
    case ImageElementFormat.Auto:
      ratio = image.width / image.height;
      break;
    case ImageElementFormat.Landscape:
      ratio = 4 / 3;
      break;
    case ImageElementFormat.Portrait:
      ratio = 3 / 4;
      break;
    case ImageElementFormat.Square:
    case ImageElementFormat.Circle:
      ratio = 1;
      break;
    case ImageElementFormat.Wide:
      ratio = 16 / 10;
      break;
    case ImageElementFormat.Extrawide:
      ratio = 3 / 1;
      break;
  }

  return (
    <div
      className={classnames(styles.imageElement, className, {
        [styles.center]: alignment === ImageElementAlignment.Center,
        [styles.right]: alignment === ImageElementAlignment.Right,
      })}
      onClick={onClick}
    >
      <div style={{ width: `${Math.min(100, scale * 100)}%` }}>
        <RatioContainer ratio={ratio}>
          <div
            className={classnames(styles.imageWrapper, imageWrapperClassName, {
              [styles.circle]: format === ImageElementFormat.Circle,
            })}
          >
            <img
              className={classnames(styles.image, imageClassName)}
              src={getImgSrc({ image, cardSize: cardSettings, env, scale })}
              alt=""
            />
          </div>
          {children}
        </RatioContainer>
      </div>
    </div>
  );
};
