import { BLACK, WHITE } from '../../utils/color/colors';
import { TCardDesign } from '../TCardDesign';

export const defaultDesign: TCardDesign = {
  fontSetId: '5c452125-449b-4240-ad95-3279000ab508',
  pageFrame: 'box',
  colors: {
    primary: BLACK,
    headlines: BLACK,
    text: BLACK,
    background: WHITE,
  },
};
