import { TBrandingStyles } from '@card/core';
import { foodcardsLogo, foodcardsLogoWhite } from '@shared/images';
import classnames from 'classnames';
import React from 'react';
import * as styles from './footerStyles.module.scss';

type Props = {
  brandingStyle: TBrandingStyles;
  projectId: string;
};

export const Footer: React.FC<Props> = (props) => {
  if (props.brandingStyle === 'non') {
    return null;
  }

  const logoSrc =
    props.brandingStyle === 'bright' ? foodcardsLogoWhite : foodcardsLogo;
  return (
    <div className={styles.footer}>
      <span
        className={classnames(styles.text, {
          [styles.white]: props.brandingStyle === 'bright',
        })}
      >
        made with
      </span>

      <a
        href={`https://www.foodcards.de?utm_source=${props.projectId}`}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className={styles.logo}
          src={logoSrc}
          height="15"
          title="foodcards | Einfach schöne Speisekarten erstellen"
          alt="foodcards"
        />
      </a>
    </div>
  );
};
