import React, { useContext } from 'react';
import { PageContext } from '../../../../../../contexts/page/PageContext';
import { useBorderSize } from '../../../../../../hooks/useBorderSize';
import { mm } from '../../../../../../utils/measurements/mm';
import { MenuTitleDecorationProps } from '../MenuTitleDecorationProps';

export const UnderlineMenuTitleDecoration: React.FC<MenuTitleDecorationProps> = (
  props
) => {
  const { cardSettings } = useContext(PageContext);
  const borderSize = useBorderSize();
  const borderSpacing = Math.max(
    1,
    1.5 * cardSettings.scale * cardSettings.spaceScale
  );

  return (
    <>
      {props.children}
      <div
        style={{
          height: `${borderSize}px`,
          marginTop: mm(borderSpacing),
          backgroundColor: cardSettings.colors.headlines,
        }}
      />
    </>
  );
};
