import { TFont } from './TFont';
import { TFontSet } from './TFontSet';

const weight = (font: TFont) => {
  let weightString = `:wght@${font.normalWeight}`;

  if (font.semiBoldWeight && font.semiBoldWeight !== font.boldWeight) {
    weightString += `;${font.semiBoldWeight}`;
  }

  if (font.normalWeight !== font.boldWeight && font.boldWeight !== 'bold') {
    weightString += `;${font.boldWeight}`;
  }
  return weightString;
};

const family = (font: TFont) => `family=${font.name.replace(/\s/g, '+')}`;

export const getFontSetEmbedString = (fontSet: TFontSet) =>
  family(fontSet.headline) +
  weight(fontSet.headline) +
  '&' +
  family(fontSet.text) +
  weight(fontSet.text);
