import { TPageContext } from './TPageContext';
import { initialCardContextValues } from '../card/initialCardContextValues';
import { getDefaultPageTemplate } from '../../creation/pageTemplates/pageTemplates';

export const initialPageContextValues: TPageContext = {
  ...initialCardContextValues,
  pageId: 'undefined',
  pageRank: -1,
  pageTemplate: getDefaultPageTemplate(),
};
