import classnames from 'classnames';
import React, { HTMLProps } from 'react';
import { TTextSizeVariant } from '../../../utils/text/TTextSizeVariant';
import * as styles from './richTextStyles.module.scss';

export const richTextClassNames: Record<TTextSizeVariant, string[]> = {
  small: [styles.richText, 'rt-variant-small'],
  medium: [styles.richText, 'rt-variant-medium'],
  // large: [styles.richText, 'rt-variant-large'],
};

type Props = { variant?: TTextSizeVariant; text?: string } & HTMLProps<
  HTMLDivElement
>;

export const RichText = React.forwardRef<HTMLDivElement, Props>(
  ({ variant = 'medium', className, text, children, ...divProps }, ref) => (
    <div
      {...divProps}
      ref={ref}
      className={classnames(richTextClassNames[variant], className)}
    >
      {text && (
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      {children}
    </div>
  )
);
