import { TEXT_PLACEHOLDER } from './textPlaceholder/textPlaceholder';

const REGEX = new RegExp(
  /^(<(p|(h\d))( class="([a-z]|-)*"){0,1}>)(<br>)*(<\/(p|(h\d))>)$/
);

export const fillPlaceholderIntoEmptyText = (text: string) => {
  const result = (text || '').match(REGEX);

  if (result && result.length === 10) {
    return `${result[1]}${TEXT_PLACEHOLDER}${result[7]}`;
  }

  return TEXT_PLACEHOLDER;
};
