import React, { useContext } from 'react';
import { PageContext } from '../../../../../contexts/page/PageContext';
import { BarDivider } from '../BarDivider/BarDivider';
import { DividerType } from '../DividerType';
import { DotsDivider } from '../DotsDivider/DotsDivider';
import { LineDivider } from '../LineDivider/LineDivider';
import { TDividerProps } from '../TDividerProps';
import * as styles from './allDividerStyles.module.scss';

const dividers: Record<
  Exclude<DividerType, DividerType.None>,
  React.ComponentType<TDividerProps>
> = {
  [DividerType.Bar]: BarDivider,
  [DividerType.Dots]: DotsDivider,
  [DividerType.Line]: LineDivider,
};

export const AllDivider = () => {
  const { cardSettings } = useContext(PageContext);
  if (cardSettings.dividerType === DividerType.None) {
    return null;
  }

  const Divider = dividers[cardSettings.dividerType];

  return (
    <div className={styles.wrapper}>
      <Divider
        color={cardSettings.colors.primary}
        scale={cardSettings.spaceScale}
      />
    </div>
  );
};
