export const ready = (onReady: () => void) => {
  if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' &&
      !(document.documentElement as any).doScroll)
  ) {
    // Handle it asynchronously to allow scripts the opportunity to delay ready
    window.setTimeout(onReady);
  } else {
    // Use the handy event callback
    document.addEventListener('DOMContentLoaded', onReady);
  }
};
