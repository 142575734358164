import { TSpacing } from './TSpacing';
import { TSpacingSize } from './TSpacingSize';

const BASE_SPACE = 3; // mm

export const VERTICAL_SPACING: Record<TSpacingSize, TSpacing> = {
  XS: {
    min: 1,
    normal: 2,
  },

  'S-Condensed': {
    min: 1,
    normal: BASE_SPACE,
  },

  S: {
    min: 1,
    normal: BASE_SPACE,
    max: 1.5 * BASE_SPACE,
  },

  M: {
    min: 2,
    normal: 1.5 * BASE_SPACE,
    max: 2 * BASE_SPACE,
  },

  L: {
    min: 4,
    normal: 3 * BASE_SPACE,
    max: 6 * BASE_SPACE,
  },
};
