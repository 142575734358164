import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import { uniq } from 'lodash-es';
import React, { HTMLProps, useCallback, useContext, useMemo } from 'react';
import { CSS_CLASS_PREFIX } from '../../../constants';
import { CardContext } from '../../../contexts/card/CardContext';
import { PageSizesContext } from '../../../contexts/page/PageSizesContext';
import { getFontSetById } from '../../../utils/fonts/getFontSetById';
import { getLineHeightFix } from '../../../utils/fonts/getLineHeightFix';
import { TFontSet } from '../../../utils/fonts/TFontSet';
import { mmValueToEm } from '../../../utils/measurements/mmValueToEm';
import { getSpacing } from '../../../utils/spacing/getSpacing';
import {
  TEXT_BOTTOM_SPACING,
  TEXT_H1_BOTTOM_SPACING,
  TEXT_H1_TOP_SPACING,
  TEXT_H2_BOTTOM_SPACING,
  TEXT_H2_TOP_SPACING,
  TEXT_H3_4_BOTTOM_SPACING,
  TEXT_H3_4_TOP_SPACING,
  TEXT_LIST_LEFT_SPACING,
  TSpacingSize,
} from '../../../utils/spacing/TSpacingSize';
import { getFontSize } from '../../../utils/text/getFontSize';
import { TTextSizeVariant } from '../../../utils/text/TTextSizeVariant';
import { richTextClassNames } from '../RichText/RichText';

type Props = HTMLProps<HTMLDivElement>;

const getContent = (params: {
  top?: string;
  bottom?: string;
}): CSSProperties => ({
  content: '" "',
  display: 'block',
  width: '100%',
  height: 0,
  marginTop: params.top,
  marginBottom: params.bottom,
});

type TextProps = {
  textColor: string;
  headlineColor: string;
  fontSet: TFontSet;
  cardScale: number;
  variant: TTextSizeVariant;
  getSpacePX: (space: TSpacingSize) => number;
};

const useRichTextStyles = makeStyles(
  {
    richText: (props: TextProps) => {
      const {
        textColor,
        headlineColor,
        fontSet,
        cardScale,
        variant,
        getSpacePX,
      } = props;

      const className = '.' + richTextClassNames[variant].join('.');

      return {
        [`& ${className}`]: {
          wordBreak: 'break-word',
        },

        [`& ${className} sup`]: {
          verticalAlign: 'super',
          fontSize: '0.7em',
          margin: '0 0.1em',
          padding: 0,
          lineHeight: 1,
        },
        [`& ${className} p, & ${className} ul, & ${className} ol, & ${className} h1, & ${className} h2, & ${className} h3`]: {
          marginTop: 0,
          marginBottom: getSpacePX('S'),
          padding: '0 !important',
        },
        [`& ${className} p:first-child,
        & ${className} ul:first-child,
        & ${className} ol:first-child,
        & ${className} h2:first-child,
        & ${className} h3:first-child,
        & ${className} h4:first-child`]: {
          marginTop: '0 !important',
        },

        [`& ${className} p:last-child,
        & ${className} ul:last-child,
        & ${className} ol:last-child,
        & ${className} h2:last-child,
        & ${className} h3:last-child,
        & ${className} h4:last-child`]: {
          marginBottom: '0 !important',
        },

        [`& ${className} p`]: {
          // add the lineheight fix back between paragraphs
          marginBottom: -getLineHeightFix(fontSet.text.lineHeight) + 'em',
          padding: '0 !important',
        },

        [`& ${className} ul, & ${className} ol`]: {
          // only the last paragraph of a list of paragraphs would need a space bottom, but I cannot only apply the style to the last p
          // and because all headlines have a marginTop, the lists are the only ones left that need a martinTop to have a space between p ul / p ol
          marginTop: getSpacePX(TEXT_BOTTOM_SPACING),
          marginLeft: getSpacePX(TEXT_LIST_LEFT_SPACING),
        },

        [`& ${className} p::before, & ${className} ul::before, & ${className} ol::before`]: getContent(
          {
            top: getLineHeightFix(fontSet.text.lineHeight) + 'em',
          }
        ),
        [`& ${className} p::after, & ${className} ul::after, & ${className} ol::after`]: getContent(
          {
            bottom: getLineHeightFix(fontSet.text.lineHeight) + 'em',
          }
        ),

        [`& ${className} p, & ${className} li`]: {
          fontSize: `${getFontSize(
            fontSet.text,
            'p',
            variant,
            cardScale
          )} !important`,
          fontFamily: fontSet.text.familyStack,
          fontWeight: fontSet.text.normalWeight,
          color: textColor,
          lineHeight: fontSet.text.lineHeight + 'em',
        },

        [`& ${className} li`]: {
          listStylePosition: 'inside',
        },

        [`& ${className} ul li`]: {
          listStyleType: 'disc !important',
        },

        [`& ${className} ol li`]: {
          listStyleType: 'decimal !important',
        },

        [`& ${className} li::marker`]: {
          color: headlineColor,
        },

        [`& ${className} p strong, & ${className} li strong`]: {
          fontWeight: fontSet.text.boldWeight,
        },

        [`& ${className} h1, & ${className} h2, & ${className} h3, & ${className} h4`]: {
          fontFamily: fontSet.headline.familyStack,
          fontWeight: fontSet.headline.normalWeight,
          color: headlineColor,
          lineHeight: fontSet.headline.lineHeight + 'em',
        },
        [`& ${className} h1 strong, & ${className} h2 strong, & ${className} h3 strong, & ${className} h4 strong`]: {
          fontWeight: fontSet.headline.boldWeight,
        },
        [`& ${className} h1::before,& ${className} h2::before, & ${className} h3::before, & ${className} h4::before`]: getContent(
          {
            top: getLineHeightFix(fontSet.headline.lineHeight) + 'em',
          }
        ),
        [`& ${className} h1::after,& ${className} h2::after, & ${className} h3::after, & ${className} h4::after`]: getContent(
          {
            bottom: getLineHeightFix(fontSet.headline.lineHeight) + 'em',
          }
        ),
        [`& ${className} h3, & ${className} h4`]: {
          marginTop: getSpacePX(TEXT_H3_4_TOP_SPACING),
          marginBottom: getSpacePX(TEXT_H3_4_BOTTOM_SPACING),
        },

        [`& ${className} h1`]: {
          fontSize: `${getFontSize(
            fontSet.headline,
            'h1',
            variant,
            cardScale
          )} !important`,
          marginTop: getSpacePX(TEXT_H1_TOP_SPACING),
          marginBottom: getSpacePX(TEXT_H1_BOTTOM_SPACING),
        },

        [`& ${className} h2`]: {
          fontSize: `${getFontSize(
            fontSet.headline,
            'h2',
            variant,
            cardScale
          )} !important`,
          marginTop: getSpacePX(TEXT_H2_TOP_SPACING),
          marginBottom: getSpacePX(TEXT_H2_BOTTOM_SPACING),
        },

        [`& ${className} h3`]: {
          fontSize: `${getFontSize(
            fontSet.headline,
            'h3',
            variant,
            cardScale
          )} !important`,
        },

        [`& ${className} h4`]: {
          fontSize: `${getFontSize(
            fontSet.headline,
            'h4',
            variant,
            cardScale
          )} !important`,
        },
      };
    },
  },
  { classNamePrefix: CSS_CLASS_PREFIX }
);

export const RichTextStylesProvider: React.FC<Props> = (props) => {
  const { baseFontSize } = useContext(PageSizesContext);
  const {
    cardSettings: {
      scale: cardScale,
      spaceScale,
      colors: { text: textColor, headlines: headlinesColor },
      fontSetId,
    },
  } = useContext(CardContext);
  const fontSet = getFontSetById(fontSetId);

  const getSpacePX = useCallback(
    (space: TSpacingSize) => {
      const fontSpacingScale = cardScale * spaceScale;
      return mmValueToEm(getSpacing(space, fontSpacingScale)) * baseFontSize;
    },
    [cardScale, spaceScale, baseFontSize]
  );

  const smallRichTextStyles = useRichTextStyles({
    cardScale,
    fontSet,
    headlineColor: headlinesColor,
    variant: 'small',
    textColor: textColor,
    getSpacePX,
  });

  const mediumRichTextStyles = useRichTextStyles({
    cardScale,
    fontSet,
    headlineColor: headlinesColor,
    variant: 'medium',
    textColor: textColor,
    getSpacePX,
  });

  const classNames = useMemo(
    () =>
      uniq([
        ...smallRichTextStyles.richText.split(' '),
        ...mediumRichTextStyles.richText.split(' '),
      ]),
    [smallRichTextStyles, mediumRichTextStyles]
  );
  return <div {...props} className={classnames(classNames)} />;
};
