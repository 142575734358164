import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import isChromatic from 'chromatic/isChromatic';
import { BASE_UNIT, COLORS } from '../constants';

const options: ThemeOptions = {
  palette: {
    primary: {
      main: COLORS.YELLOW,
      contrastText: COLORS.WHITE,
    },
    secondary: {
      main: COLORS.DARK,
    },
  },
  spacing: BASE_UNIT,
  typography: {
    fontFamily: '"Nunito Sans",Helvetica,Arial,Lucida,sans-serif',
    fontSize: 14,
    htmlFontSize: 10,
    fontWeightBold: 600,
    body1: {
      color: COLORS.DARK,
      fontWeight: 400,
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
      color: COLORS.DARK,
    },
    h2: {
      color: COLORS.DARK,
      fontSize: 18,
      fontWeight: 600,
    },
    h3: {
      fontSize: 16,
      color: COLORS.DARK,
      fontWeight: 600,
    },
    h4: {
      fontSize: 14,
      color: COLORS.DARK,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      color: COLORS.DARK,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      color: COLORS.DARK,
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
    caption: {
      fontSize: 10,
      fontWeight: 400,
    },
  },
  props: {
    MuiListItemText: {
      style: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiListItemIcon: {
      style: {
        minWidth: 30,
      },
    },
  },
};

if (isChromatic()) {
  // disable mui transitions in chromatic
  options.transitions = {
    create: () => 'none',
  };
}

export const theme = createMuiTheme(options);
