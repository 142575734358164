import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ResizeObserver } from 'resize-observer';
import { PageSizesContext } from '../../../contexts/page/PageSizesContext';
import { getBaseCardFontSize } from '../../../utils/text/getBaseCardFontSize';
import { RichTextStylesProvider } from '../../utils/RichTextStylesProvider/RichTextStylesProvider';
import * as styles from './staticCardStyles.module.scss';

type Props = {
  cardWidth: number;
  cardHeight: number;
  observeResize?: boolean;
};
export const StaticCard: React.FC<Props> = ({
  cardHeight,
  cardWidth,
  children,
  observeResize = true,
}) => {
  const pageRef = useRef<HTMLDivElement>(null);
  const [sizes, setSizes] = useState({ width: 1, baseFontSize: 1 });

  const updateSize = useCallback(() => {
    const width = (pageRef.current && pageRef.current.offsetWidth) || 1;
    const baseFontSize = getBaseCardFontSize(width, {
      width: cardWidth,
      height: cardHeight,
    });

    setSizes({
      width,
      baseFontSize,
    });
  }, [pageRef, cardWidth, cardHeight]);

  useEffect(() => {
    if (observeResize) {
      const resizeObserver = new ResizeObserver(updateSize);
      if (pageRef.current) {
        resizeObserver.observe(pageRef.current);
      }

      return () => resizeObserver.disconnect();
    } else {
      updateSize();
    }
  }, [updateSize, observeResize]);

  const cardStyle = useMemo<CSSProperties>(
    () => ({
      fontSize: sizes.baseFontSize,
    }),
    [sizes]
  );

  return (
    <div ref={pageRef} className={styles.card} style={cardStyle}>
      <PageSizesContext.Provider value={sizes}>
        <RichTextStylesProvider children={children} />
      </PageSizesContext.Provider>
    </div>
  );
};
