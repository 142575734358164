import { TImageElementData } from '@card/core';
import { TImagePlaceholdersMap } from '@card/utils';
import { getConfig } from 'config/getConfig';

const t = (i: TImageElementData): TImageElementData => i;

export const imagePlaceholders: TImagePlaceholdersMap = {
  placeholder: t({
    type: 'image',
    id: getConfig('IMAGE_PLACEHOLDER_ID'),
    name: '1px-placeholder.png',
    uri: getConfig('IMAGE_PLACEHOLDER_URI'),
    width: 1,
    height: 1,
    mimeType: 'image/png',
    bits: 8,
    category: 'placeholder',
    size: 0,
  }),

  starter: t({
    name: 'gladys-arivia-wD0R5He4LtA-unsplash.jpg',
    uri: getConfig('IMAGE_PLACEHOLDER_MOZARELLA_STICKS_URI'),
    width: 4000,
    height: 6000,
    mimeType: 'image/jpeg',
    bits: 8,
    category: 'placeholder',
    id: getConfig('IMAGE_PLACEHOLDER_MOZARELLA_STICKS_ID'),
    type: 'image',
    size: 0,
  }),

  dessert: t({
    name: 'madmax-chef-DCFt4KA0EuM-unsplash.jpg',
    uri: getConfig('IMAGE_PLACEHOLDER_CREME_BRULEE_URI'),
    width: 4540,
    height: 3029,
    mimeType: 'image/jpeg',
    bits: 8,
    category: 'placeholder',
    id: getConfig('IMAGE_PLACEHOLDER_CREME_BRULEE_ID'),
    type: 'image',
    size: 0,
  }),

  salat: t({
    name: 'anna-pelzer-IGfIGP5ONV0-unsplash.jpg',
    uri: getConfig('IMAGE_PLACEHOLDER_SALAT_URI'),
    width: 5184,
    height: 3456,
    mimeType: 'image/jpeg',
    bits: 8,
    category: 'placeholder',
    id: getConfig('IMAGE_PLACEHOLDER_SALAT_ID'),
    type: 'image',
    size: 0,
  }),
};
