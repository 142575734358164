import React, { useContext } from 'react';
import { PageContext } from '../../../../contexts/page/PageContext';
import { SlotContext } from '../../../../contexts/slot/SlotContext';
import { TImageElementData } from '../../../../data/elements/TImageElementData';
import { IImageModuleData } from '../../../../data/modules/IImageModuleData';
import { useElementData } from '../../../../hooks/useElementData';
import { mm } from '../../../../utils/measurements/mm';
import { getModuleWrapperProps } from '../../../../utils/modules/getModuleWrapperProps';
import { getSpacing } from '../../../../utils/spacing/getSpacing';
import { MODULE_SPACING } from '../../../../utils/spacing/TSpacingSize';
import { ModuleProps } from '../ModuleProps';

export const ImageModule: React.FC<ModuleProps<IImageModuleData>> = (props) => {
  const imageElement = useElementData<TImageElementData>(
    props.moduleData.references.imageElementId
  );
  const { ModuleWrapper, ImageElement, cardSettings } = useContext(PageContext);
  const { level, rank } = useContext(SlotContext);

  if (!imageElement) {
    return null;
  }

  const { format, scale, alignment } = props.moduleData.settings.elements.image;
  // double the module space for the header
  let extraSpace: string | undefined = undefined;

  if (level === 0 && rank === 0 && props.moduleData.rank === 0) {
    extraSpace = mm(getSpacing(MODULE_SPACING, cardSettings.spaceScale));
  }

  return (
    <ModuleWrapper {...getModuleWrapperProps(props)}>
      <div
        style={{
          paddingTop: extraSpace,
          paddingBottom: extraSpace,
        }}
      >
        <ImageElement
          moduleId={props.moduleData.id}
          imageElement={imageElement}
          scale={scale}
          format={format}
          alignment={alignment}
        />
      </div>
    </ModuleWrapper>
  );
};
