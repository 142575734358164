import classnames from 'classnames';
import React, { CSSProperties, useContext, useMemo } from 'react';
import { PageContext } from '../../../../../../contexts/page/PageContext';
import { PageSizesContext } from '../../../../../../contexts/page/PageSizesContext';
import { TMenuCategoryElementData } from '../../../../../../data/elements/menu/TMenuCategoryElementData';
import { useElementData } from '../../../../../../hooks/useElementData';
import { mm } from '../../../../../../utils/measurements/mm';
import { mmValueToEm } from '../../../../../../utils/measurements/mmValueToEm';
import { getMenuAlignment } from '../../../../../../utils/menu/getMenuAlignment';
import { MENU_CATEGORY_NAME_TAG } from '../../../../elements/menu/StaticMenuCategoryNamePropertyElement/StaticMenuCategoryNamePropertyElement';
import { StaticTextPropertyElement } from '../../../../elements/property/StaticTextPropertyElement/StaticTextPropertyElement';
import { TElementPropertyIdentifier } from '../../../../elements/property/TElementPropertyIdentifier';
import { MenuTitleDecorationProps } from '../MenuTitleDecorationProps';
import * as styles from './barMenuTitleDecorationStyles.module.scss';

const identifier: TElementPropertyIdentifier = {
  type: 'elementproperty',
  moduleId: '',
  elementId: '',
  property: '',
};

export const BarMenuTitleDecoration: React.FC<MenuTitleDecorationProps> = (
  props
) => {
  const { cardSettings } = useContext(PageContext);
  const { baseFontSize } = useContext(PageSizesContext);
  const headlineColor = cardSettings.colors.headlines;
  const scale = cardSettings.scale * cardSettings.spaceScale;

  const menuCategoryData = useElementData<TMenuCategoryElementData>(
    props.menuCategoryId
  )!;

  const isCentered = getMenuAlignment(cardSettings.menuVariant) === 'center';

  const barStyle = useMemo<CSSProperties>(() => {
    const borderSpacing = Math.max(1, 1.5 * scale);
    const barHeight = Math.max(1, Math.round(mmValueToEm(1) * baseFontSize));

    return {
      height: `${barHeight}px`,
      marginTop: mm(borderSpacing),
      backgroundColor: headlineColor,
      paddingRight: mm(5),
      paddingLeft: isCentered ? mm(5) : undefined,
    };
  }, [baseFontSize, headlineColor, scale, isCentered]);

  return (
    <>
      {props.children}
      <div
        className={classnames({
          [styles.centered]: isCentered,
        })}
      >
        <div className={styles.bar} style={barStyle}>
          <StaticTextPropertyElement
            className={styles.text}
            identifier={identifier}
            tag={MENU_CATEGORY_NAME_TAG}
          >
            {menuCategoryData.name}
          </StaticTextPropertyElement>
        </div>
      </div>
    </>
  );
};
