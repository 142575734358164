import { useContext, useMemo } from 'react';
import { PageContext } from '../contexts/page/PageContext';
import { PageSizesContext } from '../contexts/page/PageSizesContext';
import { mmValueToEm } from '../utils/measurements/mmValueToEm';

export const useBorderSize = () => {
  const { baseFontSize } = useContext(PageSizesContext);
  const { cardSettings } = useContext(PageContext);
  const { borderWidth } = cardSettings;

  const size = useMemo(
    () => Math.max(1, Math.round(mmValueToEm(borderWidth) * baseFontSize)),
    [baseFontSize, borderWidth]
  );

  return size;
};
