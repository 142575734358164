import {
  PageContextProvider,
  StaticCard,
  StaticComponentsCardContext,
  StaticPage,
  TElementDataMap,
  TPublishChannel,
} from '@card/core';
import { DIN_A4_PORTRAIT, getFontSetUrl } from '@card/utils';
import { Card, CircularProgress } from '@material-ui/core';
import { isIframe, isValidHexColor, isValidUUID } from '@shared/utils';
import { Footer } from 'components/Footer/Footer';
import { TCardContext } from 'libs/card/src/contexts/card/TCardContext';
import { elementsToMap } from 'libs/card/src/utils/elements/elementsToMap';
import { debounce, DebouncedFunc } from 'lodash-es';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { emmitHeight, initParent } from 'serviceClients/iframeClient';
import { getPublishChannel } from 'serviceClients/publishServiceClient';
import { getBodyHeight } from 'utils/getBodyHeight';
import { getImgSrcContextFn } from 'utils/image/getImgSrcContextFn';
import { imagePlaceholders } from 'utils/image/imagePlaceholders';
import * as styles from './embedStyles.module.scss';

type OwnProps = {};
type Props = OwnProps & RouteComponentProps<{ id: string }>;

type State = {
  channel: TPublishChannel | null;
  loading: boolean;
  color: string | undefined;
};

export class EmbedWithoutRouter extends React.Component<Props, State> {
  getImgSrc: TCardContext['getImgSrc'];
  handleResize: DebouncedFunc<any>;

  constructor(props: Props) {
    super(props);

    this.getImgSrc = getImgSrcContextFn();
    this.handleResize = debounce(() => {
      if (this.state.channel) {
        emmitHeight(this.state.channel, getBodyHeight());
      }
    }, 100);

    this.state = {
      channel: null,
      loading: true,
      color: this.getColorFromUrl(),
    };
  }

  getColorFromUrl = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const colorParam = urlParams.get('color');
    const color = colorParam
      ? isValidHexColor(colorParam, false)
        ? `#${colorParam}`
        : colorParam
      : undefined;

    return color;
  };

  componentDidMount = async () => {
    const channelId = this.props.match.params.id;
    if (isValidUUID(channelId)) {
      try {
        const channel = await getPublishChannel(channelId);
        this.getImgSrc = getImgSrcContextFn(channel.maxWidth);

        this.setState({
          channel,
          loading: false,
        });

        if (isIframe()) {
          initParent(channel, getBodyHeight());
          window.addEventListener('visibilitychange', this.handleResize);
          window.addEventListener('orientationchange', this.handleResize);
          window.addEventListener('resize', this.handleResize);
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  componentWillUnmount = () => {
    if (isIframe()) {
      window.removeEventListener('visibilitychange', this.handleResize);
      window.removeEventListener('orientationchange', this.handleResize);
      window.removeEventListener('resize', this.handleResize);
    }
    this.handleResize.cancel();
  };

  render = () => {
    const { channel } = this.state;

    if (this.state.loading) {
      return (
        <div className={styles.wrapper}>
          <CircularProgress style={{ color: this.state.color }} />
        </div>
      );
    }

    if (!channel || !channel.card) {
      return null;
    }

    const cardData = channel.card.data;

    const elements: TElementDataMap = {
      ...cardData.elements,
      ...elementsToMap(Object.values(imagePlaceholders)),
    };
    let maxWidth = channel.maxWidth;

    if (cardData.settings.documentFormat === 'din-long') {
      // calculate maxWidth based on the width of an A4 sheet
      maxWidth =
        (channel.maxWidth / DIN_A4_PORTRAIT.width) * cardData.settings.width;
    }

    return (
      <div className={styles.wrapper}>
        <link
          href={getFontSetUrl(cardData.settings.fontSetId)}
          rel="stylesheet"
        />

        <div className={styles.container} style={{ maxWidth }}>
          <div>
            <StaticComponentsCardContext
              value={{
                cardSettings: cardData.settings,
                elements,
                env: 'embed',
                getImgSrc: this.getImgSrc,
              }}
            >
              {cardData.pages.map((pageData) => (
                <Card elevation={3} className={styles.card} key={pageData.id}>
                  <StaticCard
                    cardWidth={cardData.settings.width}
                    cardHeight={cardData.settings.height}
                  >
                    <PageContextProvider
                      pageId={pageData.id}
                      pageTemplateId={pageData.templateId}
                      pageRank={pageData.rank}
                    >
                      <StaticPage
                        pageData={pageData}
                        projectId={channel.projectId}
                        noAds
                      />
                    </PageContextProvider>
                  </StaticCard>
                </Card>
              ))}
            </StaticComponentsCardContext>
          </div>

          <Footer
            brandingStyle={channel.brandingStyle}
            projectId={channel.projectId}
          />
        </div>
      </div>
    );
  };
}

export const Embed = withRouter(
  EmbedWithoutRouter
) as React.ComponentType<OwnProps>;
