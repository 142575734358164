import { TSpacing } from './TSpacing';
import { TSpacingSize } from './TSpacingSize';
import { VERTICAL_SPACING } from './verticalSpacing';

export const _getSpacing = (spacing: TSpacing, scale: number) => {
  const min = spacing.min || spacing.normal;
  const normal = spacing.normal;
  const max = spacing.max || spacing.normal;

  if (scale < 1) {
    const diff = normal - min;
    return min + diff * scale;
  }

  if (scale > 1) {
    const diff = max - normal;
    return normal + diff * (scale - 1);
  }

  return normal;
};

export const getSpacing = (spacingSize: TSpacingSize, scale: number) =>
  _getSpacing(VERTICAL_SPACING[spacingSize], scale);
